import moment from 'moment';

import jwt_decode from 'jwt-decode';

import * as Constants from '../helper/constants';

import Geocode from 'react-geocode';

import b64toBlob from 'b64-to-blob';

import { CONFIG } from '../config/constant';
import { bgvAlerts } from '../views/common-ui-components/sow/bgv-api/bgvAlerts';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { statusToAppearInReport } from '../helper/selectOptions';

Geocode.setApiKey(CONFIG.googleMaps.googleMapsApiKey);

export const strToLowercase = (str) => str.toLowerCase();

export const isEmptyObject = (val) => isNullOrEmpty(val) || (val && Object.keys(val).length === 0);

export const isEmptyArray = (val) => val && !val.length;

export const isNullOrEmpty = (str) => !str;

export const hasText = (str) => !!(str && str.trim() !== '');

export const hasNoText = (str) => !(str && str.trim() !== '');

export const parseStr = (str, replaceStr = '') => (isNullOrEmpty(str) ? replaceStr : str);

export const parseArray = (arr, replaceStr = []) => (isNullOrEmpty(arr) || isEmptyArray(arr) ? replaceStr : arr);

export const formatData = (data) => JSON.stringify(data, null, 2);

export const getCurrentDateTime = () => new Date();

export const isPastDateTime = (datetime) => datetime < getCurrentDateTime();

export const ConvertToUtc = (dateStr, dateFormat = '') =>
  moment().utc(dateStr, isNullOrEmpty(dateFormat) ? Constants.Common.DateFormat_YYYY_MM_DD : dateFormat);

export const GetDate = (dateStr, dateFormat) =>
  moment(dateStr).format(isNullOrEmpty(dateFormat) ? Constants.Common.DateFormat_YYYY__MM__DD : dateFormat);

export const GetDateAndTime = () =>
  moment().format();

export const GetTime = (dateStr, timeformat = '') =>
  moment
    .utc(dateStr)
    .local()
    .format(isNullOrEmpty(timeformat) ? Constants.Common.TimeFormat_hh_mm : timeformat);

export const GetYearDifference = (startDate, endDate) => {
  var a = moment([startDate[0], startDate[1], startDate[2]]);
  var b = moment([endDate[0], endDate[1], endDate[2]]);
  return a.diff(b, 'years', true);
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const areFilesInvalid = (filesArray) => {
  let invalidFileCount = 0;
  filesArray.forEach((oneFile) => {
    if (oneFile.type === 'image/png' || oneFile.type === 'image/jpg' || oneFile.type === 'image/jpeg' || oneFile.type === 'application/pdf') {
      if (oneFile.size > 10485760) {
        console.log('File is too large');
        invalidFileCount++;
      } else {
        console.log('File upload success');
      }
    } else {
      console.log('Invalid file type');
      invalidFileCount++;
    }
  });
  console.log('invalidCount', invalidFileCount);
  return invalidFileCount;
};

// export const areFilesInvalidTenMb = (filesArray) => {
//   let invalidFileCount = 0;
//   filesArray.forEach((oneFile) => {
//     if (oneFile.type === 'image/png' || oneFile.type === 'image/jpg' || oneFile.type === 'image/jpeg' || oneFile.type === 'application/pdf') {
//       if (oneFile.size > 10485760) {
//         console.log('File is too large');
//         invalidFileCount++;
//       } else {
//         console.log('File upload success');
//       }
//     } else {
//       console.log('Invalid file type');
//       invalidFileCount++;
//     }
//   });
//   console.log('invalidCount', invalidFileCount);
//   return invalidFileCount;
// };

export const areFilesInvalidTenMbNoPdf = (filesArray) => {
  let invalidFileCount = 0;
  filesArray.forEach((oneFile) => {
    if (oneFile.type === 'image/png' || oneFile.type === 'image/jpg' || oneFile.type === 'image/jpeg') {
      if (oneFile.size > 10485760) {
        console.log('File is too large');
        invalidFileCount++;
      } else {
        console.log('File upload success');
      }
    } else {
      console.log('Invalid file type');
      invalidFileCount++;
    }
  });
  console.log('invalidCount', invalidFileCount);
  return invalidFileCount;
};

export const areFilesInvalidTenMb = (filesArray) => {
  let invalidFileCount = 0;
  filesArray.forEach((oneFile) => {
    if (oneFile.type === 'image/png' || oneFile.type === 'image/jpg' || oneFile.type === 'image/jpeg' || oneFile.type === 'application/pdf') {
      if (oneFile.size > 10485760) {
        console.log('File is too large');
        invalidFileCount++;
      } else {
        console.log('File upload success');
      }
    } else {
      console.log('Invalid file type');
      invalidFileCount++;
    }
  });
  console.log('invalidCount', invalidFileCount);
  return invalidFileCount;
};

export const areFilesInvalidBulkUpload = (excelFile, zipFile) => {
  if (
    // file type conditions
    (zipFile.type === 'application/zip' ||
      zipFile.type === 'application/x-zip-compressed' ||
      zipFile.type === 'application/zip-compressed') &&
    (excelFile.type === 'application/vnd.ms-excel' ||
      excelFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  ) {
    if (zipFile.size > 15485760 || excelFile.size > 15485760) {
      console.log('File is too large');
      return bgvAlerts.invalidFileSizeBulkUpload;
    } else {
      console.log('File upload success');
      return null;
    }
  } else {
    console.log('Invalid file type');
    return bgvAlerts.invalidFilesPresentBulkUpload;
  }
};

export const decodeJWT = (token) => jwt_decode(token);

export const arrayToString = (array) => array.toString();

export const splitWithDelimitter = (date, delimitter) => date.split(delimitter).map(Number);

export const arrayIntersection = (array1, array2) => array1.filter((value) => array2.includes(value));

export const arrayDifference = (array1, array2) => array1.filter((x) => !array2.includes(x));

export const getAddressFromLatLong = (lat, long) => {
  // Geocode.setApiKey("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
  Geocode.fromLatLng(lat, long).then(
    (response) => {
      console.log(response);
      const address = response.results[0].formatted_address;
      return address;
    },
    (error) => {
      console.log(error);
      return error;
    }
  );
};

export const getLatLongFromAddress = async (address) => {
  console.log('address', address);
  Geocode.fromAddress(address).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      console.log(lat, lng);
      return { lat: lat, lng: lng };
    },
    (error) => {
      console.error(error);
      return { error };
    }
  );
};

export const photosModifier = (photos) => {
  let photosData = [];
  photos.map((photo, photoIndex) => {
    photosData.push({ src: photo.image, title: photo.caption, width: 1, height: 1 });
  });
  return photosData;
};

export const getMimeType = (file_ext) => {
  let file_mime;
  switch (file_ext) {
    case '.jpg':
      file_mime = 'image/jpeg';
      break;

    case '.jpeg':
      file_mime = 'image/jpeg';
      break;

    case '.txt':
      file_mime = 'text/plain';
      break;

    case '.html':
      file_mime = 'text/html';
      break;

    case '.css':
      file_mime = 'text/css';
      break;

    case '.png':
      file_mime = 'image/png';
      break;

    case '.pdf':
      file_mime = 'application/pdf';
      break;

    case '.json':
      file_mime = 'application/json';
      break;

    case '.docx':
      file_mime = 'application/octet-stream';
      break;

    case '.doc':
      file_mime = 'application/msword';
      break;

    case '.xls':
      file_mime = 'application/vnd.ms-excel';
      break;

    case '.xlsx':
      file_mime = 'application/vnd.ms-excel';
      break;

    case '.ppt':
      file_mime = 'application/vnd.ms-powerpoint';
      break;

    case '.zip':
      file_mime = 'application/zip';
      break;
  }
  return file_mime;
}

export const base64toBlob = async (b64Data) => {
  let imageExt = '.' + b64Data.split(';')[0].split('/')[1];
  let b64Content = b64Data.replace(/^data:image\/\w+;base64,/, '');

  let contentType = getMimeType(imageExt)

  var blob = b64toBlob(b64Content, contentType);
  console.log("BLOB: ", blob)
  var blobUrl = URL.createObjectURL(blob);
  console.log("BLOB URL: ", blobUrl)

  return blob
};

export const checkForDuplicates = (arr) => {
  return new Set(arr).size !== arr.length
};

export const removeDuplicatesFromArray = (arr) => {
  return [...new Set(arr)];
};

export const calculatePercentage = (value, percentage) => {
  return ((value / 100) * percentage);
};

export const calculateDueDate = (startDate, howManyDays) => {
  // let addedDate = moment(fromDate.split("-")).add(howManyDays, 'd');
  // console.log("addedDate", addedDate._d)
  // return Date(addedDate._d)

  // Get the current date
  var currentDate = new Date(startDate);
  // Add 30 days
  var dueDate = new Date(currentDate.getTime());
  dueDate.setDate(currentDate.getDate() + howManyDays);
  // Format the due date as needed
  var formattedDate = dueDate.toISOString().split('T')[0];

  // Return the formatted date
  return formattedDate;
}

export const roundOffIntegers = (Number, Place) => {
  let c = (Math.round(Number * 100) / 100).toFixed(Place);
  console.log(c);
  return c;
}

export const qcActualValue = (status, value, actual) => {
  return status == "Verified to be correct" ? value : actual;
};

export const numberToWords = (num) => {
  const ones = [
    "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
    "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
    "Seventeen", "Eighteen", "Nineteen"
  ];

  const tens = [
    "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
  ];

  if (num === 0) return "Zero";
  if (num < 20) return ones[num];

  if (num < 100) {
    return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + ones[num % 10] : "");
  }

  if (num < 1000) {
    return ones[Math.floor(num / 100)] + " Hundred" + (num % 100 !== 0 ? " " + numberToWords(num % 100) : "");
  }

  if (num < 1000000) {
    return numberToWords(Math.floor(num / 1000)) + " Thousand" + (num % 1000 !== 0 ? " " + numberToWords(num % 1000) : "");
  }

  if (num < 1000000000) {
    return numberToWords(Math.floor(num / 1000000)) + " Million" + (num % 1000000 !== 0 ? " " + numberToWords(num % 1000000) : "");
  }

  return numberToWords(Math.floor(num / 1000000000)) + " Billion" + (num % 1000000000 !== 0 ? " " + numberToWords(num % 1000000000) : "");
}

export const numberWordsToNumbers = {
  "One": 1, "Two": 2, "Three": 3, "Four": 4, "Five": 5, "Six": 6, "Seven": 7, "Eight": 8, "Nine": 9, "Ten": 10,
  "Eleven": 11, "Twelve": 12, "Thirteen": 13, "Fourteen": 14, "Fifteen": 15, "Sixteen": 16, "Seventeen": 17, "Eighteen": 18, "Nineteen": 19,
  "Twenty": 20
};

export const sortEmpEvidenceArray = (array) => {
  return array.sort((a, b) => {
    // Extract the word part from each string
    const wordA = a.match(/employmentEvidence(\w+)/i)[1];
    const wordB = b.match(/employmentEvidence(\w+)/i)[1];

    // Compare the numeric values of the words
    return numberWordsToNumbers[wordA] - numberWordsToNumbers[wordB];
  });
};

export const sortEmpDetailsArray = (array) => {
  return array.sort((a, b) => {
    // Extract the word part from each string
    const wordA = a.match(/employmentDetail(\w+)/i)[1];
    const wordB = b.match(/employmentDetail(\w+)/i)[1];

    // Compare the numeric values of the words
    return numberWordsToNumbers[wordA] - numberWordsToNumbers[wordB];
  });
};

export const getComponentsForCase = (data) => {
  const availableComponents = [
    'Address',
    'CibilCheck',
    'CompanyCheck',
    'CreditCheck',
    'Criminal',
    'CvValidation',
    'DatabaseCheck',
    'DirectorshipCheck',
    'DrugTest',
    'Education',
    'Employment',
    'GapVerification',
    'ID',
    'PoliceVerification',
    'Reference',
    'SocialMedia',
    'UanCheck'
  ]

  const keyMapping = {
    Address: 'case_address_bgv',
    CibilCheck: 'case_cibilCheck_bgv',
    CompanyCheck: 'case_company_bgv',
    CreditCheck: 'case_creditCheck_bgv',
    Criminal: "case_criminal_bgv",
    CvValidation: 'case_cv_bgv',
    DatabaseCheck: 'case_globalDB_bgv',
    DirectorshipCheck: 'case_directorship_bgv',
    DrugTest: 'case_drugTest_bgv',
    Education: 'case_education_bgv',
    Employment: 'case_employment_bgv',
    GapVerification: 'case_gap_bgv',
    ID: "case_id_bgv",
    PoliceVerification: 'case_police_bgv',
    Reference: "case_reference_bgv",
    SocialMedia: 'case_socialmedia_bgv',
    UanCheck: 'case_uan_bgv'
  };

  function getIdentificationData(caseData) {
    const fields = [
      { key: "Aadhar", value: "aadharNo" },
      { key: "PAN", value: "panNo" },
      { key: "RC", value: "rcBookNo" },
      { key: "Driver License", value: "driverLicenseNo" },
      { key: "Passport", value: "passportNo" },
      { key: "Voter ID", value: "voterIdNo" },
    ];

    const identificationData = fields
      .filter(field => caseData[field.value])
      .map(field => ({ key: field.key, value: field.value }));

    return identificationData;
  }

  const employmentDetails = sortEmpDetailsArray(Object.keys(data.case_employment_bgv).filter(key => key.startsWith(Constants.Employment.employmentDetail)));
  const educationDetails = Object.keys(data.case_education_bgv).filter(key => key.startsWith(Constants.Common.educationDetail));
  const referenceDetails = Object.keys(data.case_reference_bgv).filter(key => key.startsWith(Constants.Common.referenceDetail));
  const identificationDetails = getIdentificationData(data.case_id_bgv);

  const subComponents = {
    Employment: employmentDetails,
    Address: ['currentAddress', 'intermediateAddressOne', 'intermediateAddressTwo', 'permanentAddress'],
    Education: educationDetails,
    Reference: referenceDetails,
    Criminal: ['curCrimAddress', 'permCrimAddress'],
    ID: identificationDetails
  };

  let dropdownArray = [];

  availableComponents.forEach(key => {
    const mappedKey = keyMapping[key];
    if (mappedKey in data) {
      if (subComponents[key]) {
        if (key === "Criminal") {
          subComponents[key].forEach(subComponent => {
            if (data[mappedKey][subComponent]) {
              dropdownArray.push(`${key} - ${subComponent === 'curCrimAddress' ? 'Current Address' : 'Permanent Address'}`);
            }
          });
        } else if (key === "ID") {
          subComponents[key].forEach(subComponent => {
            if (data[mappedKey][subComponent.value]) {
              dropdownArray.push(`${key} - ${subComponent.key}`);
            }
          });
        }
        else {
          subComponents[key].forEach(subComponent => {
            if (!isEmptyObject(data[mappedKey][subComponent])) {
              dropdownArray.push(`${key} - ${subComponent}`);
            }
          });
        }
      } else {
        if (!isEmptyObject(data[mappedKey])) {
          dropdownArray.push(key);
        }
      }
    }
  });
  return dropdownArray;
};

export const sweetAlertHandler = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: alert.title,
    text: alert.text,
    icon: alert.type,
    didOpen: () => {
      document.querySelector('.swal2-container').style.zIndex = '9999';
    }
  });
};

export const compareStringAndLabel = (st1, st2) => {
  return st1 === st2 ? 'Verified to be correct' : 'Verified to be incorrect';
};

export const getComponentData = (case_data, componentName) => {
  let component = componentName;
  let subComponent = '';

  if (componentName.includes('-')) {
    [component, subComponent] = componentName.split('-');
  }

  let bgv = "";
  let subObject = "";
  let componentData = {};

  switch (component) {
    case 'address':
    case 'education':
    case 'employment':
    case 'reference':
      bgv = Constants.uploadEvidenceKeyMapping[component].bgvKey;
      subObject = Constants.componentDataKeyMapping[component][subComponent];

      if (case_data[bgv] && Object.keys(case_data[bgv]).length !== 0) {
        if (case_data[bgv][subObject] && Object.keys(case_data[bgv][subObject])) {
          componentData = case_data[bgv][subObject];
        }
      }
      break;
    case 'police':
    case 'cv':
    case 'id':
    case 'socialmedia':
    case 'globaldb':
    case 'creditcheck':
    case 'cibilcheck':
    case 'drugtest':
    case 'uan':
    case 'gap':
    case 'company':
    case 'directorship':
      bgv = Constants.uploadEvidenceKeyMapping[component].bgvKey;

      if (case_data[bgv] && Object.keys(case_data[bgv]).length !== 0) {
        componentData = case_data[bgv];
      }
      break;
    case 'criminal':
      if (case_data.case_criminal_bgv && Object.keys(case_data.case_criminal_bgv).length !== 0) {
        if (subComponent === 'permanent' && case_data.case_criminal_bgv.permCrimAddress && case_data.case_criminal_bgv.permCrimCity) {
          componentData = {
            permCrimAddress: case_data.case_criminal_bgv.permCrimAddress,
            permCrimCity: case_data.case_criminal_bgv.permCrimCity
          };
        }
        if (subComponent === 'current' && case_data.case_criminal_bgv.curCrimAddress && case_data.case_criminal_bgv.curCrimCity) {
          componentData = {
            curCrimAddress: case_data.case_criminal_bgv.curCrimAddress,
            curCrimCity: case_data.case_criminal_bgv.curCrimCity
          };
        }
      }
      break;
    default:
      console.log("Invalid component name");
  }

  return componentData;
}

export const getEvidenceUploadKeys = (componentName) => {
  let bgv = "";
  let evidence = "";
  let urlField = "";

  let component = componentName;
  let subComponent = '';

  if (componentName.includes('-')) {
    [component, subComponent] = componentName.split('-');
  }

  switch (component) {
    case 'address':
    case 'education':
    case 'employment':
    case 'reference':
      bgv = Constants.uploadEvidenceKeyMapping[component].bgvKey;
      evidence = Constants.uploadEvidenceKeyMapping[component][subComponent];
      urlField = Constants.uploadEvidenceKeyMapping[component].urlField;
      break;
    case 'criminal':
    case 'police':
    case 'cv':
    case 'id':
    case 'socialmedia':
      bgv = Constants.uploadEvidenceKeyMapping[component].bgvKey;
      evidence = Constants.uploadEvidenceKeyMapping[component].evidenceField;
      urlField = Constants.uploadEvidenceKeyMapping[component][subComponent];
      break;
    case 'globaldb':
    case 'creditcheck':
    case 'cibilcheck':
    case 'drugtest':
    case 'uan':
    case 'gap':
    case 'company':
    case 'directorship':
      bgv = Constants.uploadEvidenceKeyMapping[component].bgvKey;
      evidence = Constants.uploadEvidenceKeyMapping[component].evidenceField;
      urlField = Constants.uploadEvidenceKeyMapping[component].urlField;
      break;
    default:
      console.log("Invalid component name");
  }

  return { bgv, evidence, urlField };
}

export const checkComponentData = (caseData, component, statusField) => {
  const validComponents = [];

  switch (component) {
    case "address":
    case "education":
    case "employment":
    case "reference":
      const compKeys = Object.keys(Constants.componentUtilityKeys).filter(key => key.startsWith(component));

      for (const key of compKeys) {
        const { bgv, evidence } = getEvidenceUploadKeys(Constants.componentUtilityKeys[key]);
        if (
          caseData &&
          caseData[bgv] &&
          caseData[bgv][evidence] &&
          Object.keys(caseData[bgv][evidence]).length > 0
        ) {
          const statusValue = caseData[bgv][evidence][statusField];
          if (statusValue && statusToAppearInReport.includes(statusValue)) {
            console.log({ key, statusValue })
            validComponents.push(evidence);
          }
        }
      }
      break;
    case Constants.componentUtilityKeys.policeOne:
    case Constants.componentUtilityKeys.cvOne:
    case Constants.componentUtilityKeys.socialmediaFacebook:
    case Constants.componentUtilityKeys.globalDB:
    case Constants.componentUtilityKeys.creditCheck:
    case Constants.componentUtilityKeys.cibilCheck:
    case Constants.componentUtilityKeys.drugTest:
    case Constants.componentUtilityKeys.uan:
    case Constants.componentUtilityKeys.gap:
    case Constants.componentUtilityKeys.company:
    case Constants.componentUtilityKeys.directorship:
      const { bgv, } = getEvidenceUploadKeys(component);
      if (
        caseData &&
        caseData[bgv] &&
        Object.keys(caseData[bgv]).length > 0
      ) {
        const statusValue = caseData[bgv][statusField];
        if (statusValue && statusToAppearInReport.includes(statusValue)) {
          console.log({ component, statusValue })
          validComponents.push(bgv);
        }
      }
      break;
    case "criminal":
      if (
        caseData &&
        caseData[`case_criminal_bgv`] &&
        caseData[`case_criminal_bgv`]['addressEvidence'] &&
        Object.keys(caseData[`case_criminal_bgv`]['addressEvidence']).length > 0
      ) {
        const currStatus = caseData[`case_criminal_bgv`]['addressEvidence'].currStatus || '';
        const permStatus = caseData[`case_criminal_bgv`]['addressEvidence'].permStatus || '';
        if (statusToAppearInReport.includes(currStatus)) {
          validComponents.push('current');
        }
        if (statusToAppearInReport.includes(permStatus)) {
          validComponents.push('permanent');
        }
      }
      break;
    case `identification`:
      if (
        caseData &&
        caseData[`case_id_bgv`] &&
        Object.keys(caseData[`case_id_bgv`]).length > 0
      ) {
        if (statusToAppearInReport.includes(caseData.case_id_bgv.componentStatus)) {
          if (caseData.case_id_bgv.aadharFile !== "") {
            validComponents.push('aadhar')
          }
          if (caseData.case_id_bgv.panFile !== "") {
            validComponents.push('pan')
          }
          if (caseData.case_id_bgv.passportFile !== "") {
            validComponents.push('passport')
          }
          if (caseData.case_id_bgv.rcBookFile !== "") {
            validComponents.push('rcBook')
          }
          if (caseData.case_id_bgv.voterIdFile !== "") {
            validComponents.push('voterId')
          }
          if (caseData.case_id_bgv.driverLicenseFile !== "") {
            validComponents.push('driverLicense')
          }
        }
      }
      break;
    default:
      console.log('Invalid status field');
      break;
  }
  return validComponents;
};