import { bgvStatus, bgvStatusValues, davStatusLabels, davStatusValues } from '../views/common-ui-components/sow/bgv-api/dbMapping';
import { ConstantOwnershipTypeLabels, ConstantOwnershipTypes, ConstantRelationshipTypeLabels, ConstantRelationshipTypes, DigitalAddressTypeLabels, DigitalAddressTypes, PortalUserRoles, PortalUserRolesDisplayText, invoiceTypes } from './constants';

export const idTypes = [
  {
    value: 'aadhar',
    label: 'Aadhar Card'
  },
  {
    value: 'dl',
    label: 'Driving Licence'
  },
  {
    value: 'voterid',
    label: 'Voter Id'
  },
  {
    value: 'passport',
    label: 'Passport'
  },
  {
    value: 'pan',
    label: 'Pan Card'
  },
  {
    value: 'nationalid',
    label: 'National Id'
  }
];

export const componentsArray = [
  { value: '', label: 'Select a component' },
  { value: 'Identification', label: 'Identification' },
  { value: 'Address', label: 'Address' },
  { value: 'DigitalAddress', label: 'DigitalAddress' },
  { value: 'CibilCheck', label: 'Cibil Check' },
  { value: 'CompanyCheck', label: 'Company Check' },
  { value: 'CreditCheck', label: 'Credit Check' },
  { value: 'Criminal', label: 'Criminal' },
  { value: 'CvValidation', label: 'Cv Validation' },
  { value: 'DatabaseCheck', label: 'Database Check' },
  { value: 'DirectorshipCheck', label: 'Directorship Check' },
  { value: 'DrugTest', label: 'Drug Test' },
  { value: 'Education', label: 'Education' },
  { value: 'Employment', label: 'Employment' },
  { value: 'GapVerification', label: 'Gap Verification' },
  { value: 'UanCheck', label: 'UanCheck', color: 'black' },
  { value: 'PoliceVerification', label: 'Police Verification' },
  { value: 'Reference', label: 'Reference' },
  { value: 'SocialMedia', label: 'Social Media' }
];

export const addressTypeOptions = [
  { value: '', label: 'Select an option' },
  { value: DigitalAddressTypes.Permanent, label: 'Permanent' },
  { value: DigitalAddressTypes.Current, label: 'Current' }
  // { value: 'Intermediate1', label: 'Intermediate1' },
  // { value: 'Intermediate2', label: 'Intermediate2' }
];

export const addressTypeOptionsArray = ['Permanent', 'Current'];

export const dbEvidenceTypeOptions = [
  { value: '', label: 'Select an option' },
  { value: 'IndiaSpecific', label: 'India-specific Database Checks' },
  { value: 'Global', label: 'Global Database Checks' },
  { value: 'WebAndMedia', label: 'Web and Media Checks' }
];

export const dbEvidenceTypeOptionsArray = ['IndiaSpecific', 'Global', 'WebAndMedia'];

// Verification part -----------------------------------------------------

export const verificationOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Verified to be correct', label: 'Verified to be correct' },
  { value: 'Verified to be incorrect', label: 'Verified to be incorrect' }
];

// this should match the verificationOptions.value
export const verificationOptionsArray = ['Verified to be correct', 'Verified to be incorrect'];

export const evidenceUploadStatusOptions = [
  { value: '', label: 'Select an option' },
  { value: bgvStatusValues.Awaiting, label: bgvStatus.Awaiting },
  { value: bgvStatusValues.Wip, label: bgvStatus.Wip },
  { value: bgvStatusValues.Completed, label: bgvStatus.Completed },
  { value: bgvStatusValues.UnableToVerify, label: bgvStatus.UnableToVerify },
  { value: bgvStatusValues.Insufficient, label: bgvStatus.Insufficient },
  { value: bgvStatusValues.Discrepancy, label: bgvStatus.Discrepancy },
  { value: bgvStatusValues.BgvStop, label: bgvStatus.BgvStop },
  { value: bgvStatusValues.NotProvided, label: bgvStatus.NotProvided },
  { value: bgvStatusValues.PartiallyCompleted, label: bgvStatus.PartiallyCompleted },
];

export const davStatusOptions = [
  { value: '', label: 'Select an option' },
  { value: bgvStatusValues.Awaiting, label: bgvStatus.Awaiting },
  { value: bgvStatusValues.Wip, label: bgvStatus.Wip },
  { value: bgvStatusValues.Completed, label: bgvStatus.Completed },
  { value: bgvStatusValues.UnableToVerify, label: bgvStatus.UnableToVerify },
  { value: bgvStatusValues.Insufficient, label: bgvStatus.Insufficient },
  { value: bgvStatusValues.Discrepancy, label: bgvStatus.Discrepancy },
  { value: bgvStatusValues.BgvStop, label: bgvStatus.BgvStop },
];

export const indivDavStatusOptions = [
  { value: '', label: 'Select an option' },
  { value: davStatusValues.Awaiting, label: davStatusLabels.Awaiting },
  { value: davStatusValues.Wip, label: davStatusLabels.Wip },
  { value: davStatusValues.Submitted, label: davStatusLabels.Submitted },
  { value: davStatusValues.ReInitiated, label: davStatusLabels.ReInitiated },
  { value: davStatusValues.Completed, label: davStatusLabels.Completed },
  { value: davStatusValues.UnableToVerify, label: davStatusLabels.UnableToVerify },
  { value: davStatusValues.Discrepancy, label: davStatusLabels.Discrepancy },
  { value: davStatusValues.Insufficient, label: davStatusLabels.Insufficient },
];

export const evidenceUploadStatusOptionsArray = [
  bgvStatusValues.Awaiting,
  bgvStatusValues.Wip,
  bgvStatusValues.Completed,
  bgvStatusValues.UnableToVerify,
  bgvStatusValues.Insufficient,
  bgvStatusValues.Discrepancy,
  bgvStatusValues.BgvStop,
  bgvStatusValues.NotProvided,
  bgvStatusValues.PartiallyCompleted,
];

export const verificationStatusOptions = [
  { value: '', label: 'Select an option' },
  { value: bgvStatusValues.Completed, label: bgvStatus.Completed },
  { value: bgvStatusValues.UnableToVerify, label: bgvStatus.UnableToVerify },
  { value: bgvStatusValues.Insufficient, label: bgvStatus.Insufficient },
  { value: bgvStatusValues.Discrepancy, label: bgvStatus.Discrepancy }
];

export const verificationStatusOptionsArray = [
  bgvStatusValues.Completed,
  bgvStatusValues.UnableToVerify,
  bgvStatusValues.Insufficient,
  bgvStatusValues.Discrepancy
];

export const statusToAppearInReport = [
  bgvStatusValues.Completed,
  bgvStatusValues.Discrepancy,
  bgvStatusValues.UnableToVerify,
  bgvStatusValues.Insufficient
];

export const evidenceFileAvailabilityOptionsArray = [
  bgvStatusValues.Completed,
  bgvStatusValues.UnableToVerify,
  bgvStatusValues.BgvStop,
  bgvStatusValues.Discrepancy
];

export const verificationTypeOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Police', label: 'Police' }
];

export const verificationTypeOptionsArray = ['Police'];

export const verificationModeOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Written', label: 'Written' }
];

export const verificationModeOptionsArray = ['Written'];

export const eligibleForRehireOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

// this should match the eligibleForRehireOptions.value
export const eligibleForRehireOptionsArray = ['Yes', 'No'];

export const YesNoOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

export const YesNoArray = ['Yes', 'No'];

export const modeOfVerificationOptions = [
  { value: '', label: 'Select an option' },
  { value: 'E-Communication', label: 'E-Communication' },
  { value: 'Online', label: 'Online' },
  { value: 'Liaison', label: 'Liaison' },
  { value: 'Personal Visit', label: 'Personal Visit' }
];

// this should match the modeOfVerificationOptions.value
export const modeOfVerificationOptionsArray = ['E-Communication', 'Online', 'Liaison', 'Personal Visit'];

export const foundNotFoundOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Found', label: 'Found' },
  { value: 'Not Found', label: 'Not Found' }
];

// this should match the foundNotFoundOptions.value
export const foundNotFoundOptionsArray = ['Found', 'Not Found'];

export const contactRelationshipOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Colleague', label: 'Colleague' },
  { value: 'Friend', label: 'Friend' },
  { value: 'Others', label: 'Others' }
];

export const DigitalAddressTypeOptions = [
  { label: DigitalAddressTypeLabels.Permanent, value: DigitalAddressTypes.Permanent },
  { label: DigitalAddressTypeLabels.Current, value: DigitalAddressTypes.Current },
  { label: DigitalAddressTypeLabels.IntermediateOne, value: DigitalAddressTypes.IntermediateOne },
  { label: DigitalAddressTypeLabels.IntermediateTwo, value: DigitalAddressTypes.IntermediateTwo }
];

export const OwnershipTypeOptions = [
  { label: 'Select an option', value: '' },
  { label: ConstantOwnershipTypeLabels.Rented, value: ConstantOwnershipTypes.Rented },
  { label: ConstantOwnershipTypeLabels.Owned, value: ConstantOwnershipTypes.Owned },
  { label: ConstantOwnershipTypeLabels.Leased, value: ConstantOwnershipTypes.Leased },
  { label: ConstantOwnershipTypeLabels.PG, value: ConstantOwnershipTypes.PG },
  { label: ConstantOwnershipTypeLabels.Other, value: ConstantOwnershipTypes.Other }
];

export const RelationshipTypeOptions = [
  { label: 'Select an option', value: '' },
  { label: ConstantRelationshipTypeLabels.Self, value: ConstantRelationshipTypes.Self },
  { label: ConstantRelationshipTypeLabels.BloodRelative, value: ConstantRelationshipTypes.BloodRelative },
  { label: ConstantRelationshipTypeLabels.Spouse, value: ConstantRelationshipTypes.Spouse },
  { label: ConstantRelationshipTypeLabels.Neighbour, value: ConstantRelationshipTypes.Neighbour },
  { label: ConstantRelationshipTypeLabels.Other, value: ConstantRelationshipTypes.Other }
];

// this should match the contactRelationshipOptions.value
export const contactRelationshipOptionsArray = ['Colleague', 'Friend', 'Others'];

export const DigitalAddressVerificationTypesArray = [
  DigitalAddressTypes.Permanent,
  DigitalAddressTypes.Current,
  DigitalAddressTypes.IntermediateOne,
  DigitalAddressTypes.IntermediateTwo
];

export const RelationshipTypesArray = [
  ConstantRelationshipTypes.Self,
  ConstantRelationshipTypes.BloodRelative,
  ConstantRelationshipTypes.Spouse,
  ConstantRelationshipTypes.Neighbour,
  ConstantRelationshipTypes.Other
];

export const OwnershipTypesArray = [
  ConstantOwnershipTypes.Rented,
  ConstantOwnershipTypes.Owned,
  ConstantOwnershipTypes.Leased,
  ConstantOwnershipTypes.PG,
  ConstantOwnershipTypes.Other
];

export const davVerificationStatusOptionsArray = [
  davStatusValues.Submitted,
  davStatusValues.Completed,
  davStatusValues.ReInitiated,
  davStatusValues.UnableToVerify,
  davStatusValues.Discrepancy,
  davStatusValues.Insufficient
];

export const davVerificationStatusOptionsConfirmationArray = [
  davStatusValues.Completed,
  davStatusValues.UnableToVerify,
  davStatusValues.Discrepancy,
  davStatusValues.Insufficient
];

export const selectPortalUserOptions = [
  { value: PortalUserRoles.Admin, text: PortalUserRolesDisplayText.Admin },
  { value: PortalUserRoles.Client, text: PortalUserRolesDisplayText.Client },
  { value: PortalUserRoles.Sales, text: PortalUserRolesDisplayText.Sales },
  { value: PortalUserRoles.CstSupervisor, text: PortalUserRolesDisplayText.CstSupervisor },
  { value: PortalUserRoles.CstUser, text: PortalUserRolesDisplayText.CstUser },
  { value: PortalUserRoles.OpsSupervisor, text: PortalUserRolesDisplayText.OpsSupervisor },
  { value: PortalUserRoles.OpsTeam, text: PortalUserRolesDisplayText.OpsTeam },
  { value: PortalUserRoles.QualityControl, text: PortalUserRolesDisplayText.QualityControl },
  { value: PortalUserRoles.Accounts, text: PortalUserRolesDisplayText.Accounts }
];

export const calendarMonthsOptions = [
  { value: '', text: 'Select a month' },
  { value: '01', text: 'January' },
  { value: '02', text: 'February' },
  { value: '03', text: 'March' },
  { value: '04', text: 'April' },
  { value: '05', text: 'May' },
  { value: '06', text: 'June' },
  { value: '07', text: 'July' },
  { value: '08', text: 'August' },
  { value: '09', text: 'September' },
  { value: '10', text: 'October' },
  { value: '11', text: 'November' },
  { value: '12', text: 'December' },
];

export const calendarYearOptions = [
  { value: '', text: 'Select a year' },
  { value: '2022', text: '2022' },
  { value: '2023', text: '2023' },
  { value: '2024', text: '2024' },
  { value: '2025', text: '2025' },
]

export const invoiceTypeOptions = [
  { value: "", label: "Select a type" },
  { value: invoiceTypes.Proforma, label: invoiceTypes.Proforma },
  { value: invoiceTypes.Commercial, label: invoiceTypes.Commercial },
]

export const TickMarkOptions = [
  bgvStatusValues.Completed,
  bgvStatusValues.BgvStop,
  bgvStatusValues.Discrepancy
];