
export const AddClientForm = {
  BillingAddressRequired: "Billing address is required!",
  BillingAddressTooLong: "Billing address is too long!",
  BillingAddressTooShort: "Billing address is too short!",
  BillingAddressStateRequired: "Billing state is required",
  ClientAddressRequired: "Client address is required!",
  ClientAddressTooLong: "Client address is too long!",
  ClientAddressTooShort: "Client address is too short!",
  ClientAddressStateRequired: "Client state is required",
  ClientGSTInvalid: "GST No is invalid!",
  ClientGSTRequired: "GST No is required!",
  ClientNameRequired: "Client name is required!",
  ClientNameTooLong: "Client name is too long!",
  ClientNameTooShort: "Client name is too short!",
  ClientNameValidation: "User name should contain only alphabets!",
  EntityNameRequired: "Entity name is required!",
  EntityNameTooLong: "Entity name is too long!",
  EntityNameTooShort: "Entity name is too short!",
  EntityNameValidation: "Entity name should contain only alphabets!",
  PricingOnlyNumber: "Pricing should contain only numbers!",
  PricingRegex: /^\d+$/,
  PricingRequired: "Pricing is required!",
  PrimaryContactTooLong: "Primary contact is too long!",
  PrimaryContactTooShort: "Primary contact is too short!",
  PrimaryContactRegExp: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  PrimaryContactRequired: "Primary contact is required!",
  SubCategoryRequired: "Sub category is required!",
  SubCategoryTooShort: "Sub category is too short!",
  SubCategoryTooLong: "Sub category is too long!",
  SecondaryContactRegExp: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  SecondaryContactRequired: "Secondary contact is required!",
  SecondaryContactTooLong: "Secondary contact is too long!",
  SecondaryContactTooShort: "Secondary contact is too short!",
  UserNameRegex: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
  UserNameValidation: "User name should contain only alphabets!",
  ValidContact: "Must be a valid contact number!",
};

export const AddressForm = {
  addressProofRequired: "address proof is required",
  curAddressRequired: "Address is required!",
  curAddressTooLong: "Address is too long!",
  curAddressTooShort: "Address is too short!",
  curCityRequired: "City is required!",
  curCityTooLong: "City is too long!",
  curCityTooShort: "City is too short!",
  curCountryRequired: "Country is required!",
  curCountryTooLong: "Country is too long!",
  curCountryTooShort: "Country is too short!",
  curLandMarkAlphabets: "Land mark must contain only alphabets!",
  curLandMarkRequired: "Land mark is required!",
  curLandMarkTooLong: "Land mark is too long!",
  curLandMarkTooShort: "Land mark is too short!",
  curPhoneNoRequired: "Contact number is required!",
  curPhoneNoValied: "Enter the valid phone number!",
  curPhoneRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  curPostalCodeRequired: "Postal code is required!",
  curPostalCodeTooLong: "Postal code is too long!",
  curPostalCodeTooShort: "Postal code is too short!",
  curProofTypeRequired: "Proof type is required!",
  curProofTypeTooLong: "Proof type is too long",
  curProofTypeTooShort: "Proof type is too short",
  curStateRequired: "State is required",
  curStateTooLong: "State is long!",
  curStateTooShort: "State is too short!",
  curStayPeriodRequired: "Stay period is required!",
  curStayPeriodTooLong: "Stay period is too long!",
  curStayPeriodTooShort: "Stay period is too short!",
  postalCodeRegex: `^[1-9][0-9]{5}$`,
  validCity: "City must contain only alphabets!",
  validContry: "Contry must contain only alphabets!",
  valiedPostalCode: "Must be valid postal code!",
  valiedProofType: "Must be valid proof type!",
  validState: "State must contain only alphabets!",

  permAddressRequired: "Permanent address is required!",
  permAddressTooLong: "Permanent Address is too long!",
  permAddressTooShort: "Permanent address is too short!",
  permCityRequired: "Permanent City is required!",
  permCityTooLong: "Permanent City is too long!",
  permCityTooShort: "Permanent City is too short!",
  permCountryRequired: "Permanent Country is required!",
  permCountryTooLong: "Permanent Country is too long!",
  permCountryTooShort: "Permanent Country is too short!",
  permLandMarkRequired: "Permanent Land mark is required!",
  permLandMarkTooLong: "Permanent Land mark is too long!",
  permLandMarkTooShort: "Permanent Land mark is too short!",
  permPhoneNoValied: "Enter the valid contact number!",
  permPhoneRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  permPhoneRequired: "Contact number is required!",
  permPostalCodeRequired: "Permanent Postal code is required!",
  permPostalCodeTooLong: "Permanent Postal code is too long!",
  permPostalCodeTooShort: "Permanent Postal code is too short!",
  permProofTypeRequired: "Permanent Proof type is required!",
  permProofTypeTooLong: "Permanent Proof type is too long!",
  permProofTypeTooShort: "Permanent Proof type is too short!",
  permStateRequired: "Permanent State is required!",
  permStateTooLong: "Permanent State is long!",
  permStateTooShort: "Permanent State is too short!",
  permStayPeriodRequired: "Permanent Stay period is required!",
  permStayPeriodTooLong: "Permanent Stay period is too long!",
  permStayPeriodTooShort: "Permanent Stay period is too short!",
  validPermPhone: "Must be valid contact number!",

  prevAddressRequired: "Previous address is required!",
  prevAddressTooLong: "Previous Address is too long!",
  prevAddressTooShort: "Previous address is too short!",
  prevCityRequired: "Previous City is required!",
  prevCityTooLong: "Previous City is too long!",
  prevCityTooShort: "Previous City is too short!",
  prevCountryRequired: "Previous Country is required!",
  prevCountryTooLong: "Previous Country is too long!",
  prevCountryTooShort: "Previous Country is too short!",
  prevLandMarkRequired: "Previous Land mark is required!",
  prevLandMarkTooLong: "Previous Land mark is too long!",
  prevLandMarkTooShort: "Previous Land mark is too short!",
  prevPhoneNoValied: "Enter the valid contact number!",
  prevPhoneRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  prevPhoneRequired: "Contact number is required!",
  prevPostalCodeRequired: "Previous Postal code is required!",
  prevPostalCodeTooLong: "Previous Postal code is too long!",
  prevPostalCodeTooShort: "Previous Postal code is too short!",
  prevProofTypeRequired: "Previous Proof type is required!",
  prevProofTypeTooLong: "Previous Proof type is too long!",
  prevProofTypeTooShort: "Previous Proof type is too short!",
  prevStateRequired: "Previous State is required!",
  prevStateTooLong: "Previous State is long!",
  prevStateTooShort: "Previous State is too short!",
  prevStayPeriodRequired: "Previous Stay period is required!",
  prevStayPeriodTooLong: "Previous Stay period is too long!",
  prevStayPeriodTooShort: "Previous Stay period is too short!",

  validProofType: "Proof type must contain only alphabets"
};

export const AddUserForm = {
  ConfAndNewPasswordMatch: "New Password and Confirm Password should match",
  ConfirmPasswordRequired: "Confirm Password is required!",
  CurrAndNewPasswordMatch: "New Password must be different from Current Password",
  EmailRequired: "Email Id is required!",
  GSTNumberMustHaveMinimum5Characters: "GST Number must have at least 5 characters",
  NewPasswordRequired: "New Password is required!",
  OldPasswordRequired: "Old Password is required!",
  PhonNumberIsNotValid: "Enter Valid Phone Number",
  PasswordMustHaveMinimum8Characters: "Password must have Minimum 8 Characters!",
  PasswordRegExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  PasswordRequired: "Password is required!",
  PasswordValidation: "Password must have Atleast One Uppercase, Lowercase, Number, Special Character!",
  PhonNumberRequired: "Phone number is required!",
  PhoneNumberRegExp: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  // the phone number regex accepts (123) 456-7890, (123)456-7890, 123-456-7890, 123.456.7890, 1234567890, +31636363634, 075-63546725 formats

  RegdAddressMustHaveMinimum4Characters: "Registered Address Must Have Minimum 4 Characters",
  RegdAddressRequired: "Registered address is required",
  UserNameRegex: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
  UserNameValidation: "User name should contain only alphabets!",
  // 1) test[space]ing - Should be allowed
  // 2) testing - Should be allowed
  // 3) [space]testing - Should not be allowed
  // 4) testing[space] - Should be allowed but have to trim it
  // 5) testing[space][space] - should be allowed but have to trim it
  // 6) Numbers are not allowed
  UserNameIsRequired: "User name is required!",
  UserNameIsTooLong: "User name is too long!",
  UserNameIsTooShort: "User name is too short!",
  ValiedEmail: "Must be a Valid Email Id!",
  ValidPhoneNumber: "Enter Valid Phone Number!",
  VendorNameIsRequired: "Vendor name is required!",
  VendorNameIsTooLong: "Vendor name is too long!",
  VendorNameIsTooShort: "Vendor name is too short!"
};

export const BasicDetailsForm = {
  aadhaarCardRegex: /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/,
  caseReceivedDateAhead: "Case Received Date cannot be ahead of current date!",
  caseReceivedDateRequired: "Case Received Date is required!",
  cityTooLong: "City is too long!",
  cityTooShort: "City is too short!",
  consentCheckboxRequired: "You need to accept the terms and conditions!",
  empAadharNoRequired: "Aadhar number is required!",
  empBirthCountryRequired: "Country of birth is required!",
  empCitizenshipRequired: "Citizenship is required!",
  empCityRequired: "City is required!",
  empDobAhead: "Date of birth cannot be ahead of current date!",
  empDobRequired: "Date of birth is required!",
  empEmailRequired: "Email is required!",
  empFatherNameRequired: "Father name is required!",
  empFatherNameTooLong: "Father name is too long!",
  empFatherNameTooShort: "Father name is too short!",
  empGenderRequired: "",
  empJoiningDateRequired: "Date of joining is required!",
  empLandmarkRequired: "Land mark is required!",
  empPanNoRequired: "Pan number is required!",
  empPhoneRequired: "Phone number is required!",
  empStateRequired: "State is required!",
  EndDate: "End Date",
  endDateCannotBeBeforeStartDate: "End Date cannot be before Start Date",
  endDateIsRequired: "End Date is required",
  firstNameRequired: "First name is required!",
  firstNameTooLong: "First name is too long",
  firstNameTooShort: "First name is too short",
  landmarkTooLong: "Land mark is too long!",
  landmarkTooShort: "Land mark is too short!",
  lastNameRequired: "Last name is required!",
  lastNameTooLong: "Last name is too long!",
  lastNameTooShort: "Last name is too short!",
  maxDate: "Date cannot be in the future",
  minDate: "1900-01-01",
  minDateVal: "Joining date cannot be that old ",
  panRegex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
  phonRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  startDate: "startDate",
  StartDate: "Start Date",
  startDateIsRequired: "Start Date is required",
  stateTooLong: "State is too long!",
  stateTooShort: "State is too short!",
  validAadhar: "Must be valid aadhar card number!",
  validCity: "City must contain only alphabets!",
  ValidEmail: "Must be a valid email",
  validFatherName: "Father name should contain only alphabets!",
  validFirstName: "First name should contain only alphabets!",
  validLastName: "Last name should contain only alphabets!",
  validPanNumber: "Pan number must be in BNZAA2318J format!",
  validState: "State must contain only alphabets!",
  valiedPhoneNumber: "Must be valid phone number!",
};

export const Base64FileMinLength = 3000;

export const cibilCheckForm = {
  nameAlphabets: "Name should contain only alphabets!",
  nameRequired: "Name is required!",
  nameTooLong: "Name is too long!",
  nameTooShort: "Name is too short!",
};

export const ClientPricingForm = {
  AdditionalCostInvalid: "Additional cost is invalid!",
  AdditionalCostRequired: "Additional cost is required!",
  NoComponentsAdded: "No components found. Please add the components and try again.",
  PriceInvalid: "Price is invalid!",
  PriceRequired: "Price is required!",
}

export const Colors = {
  AMBER: "AMBER",
  black: "black",
  GREEN: "GREEN",
  RED: "RED",
  white: "white",
}

export const Common = {
  Address: "Address",
  Education: "Education",
  Employment: "Employment",
  Reference: "Reference",
  DatabaseCheck: "DatabaseCheck",
  CibilCheck: "CibilCheck",
  Criminal: "Criminal",
  CreditCheck: "CreditCheck",
  CvValidation: "CvValidation",
  DrugTest: "DrugTest",
  GapVerification: "GapVerification",
  Identification: "Identification",
  CompanyCheck: "CompanyCheck",
  DirectorshipCheck: "DirectorshipCheck",
  PoliceVerification: "PoliceVerification",
  SocialMedia: "SocialMedia",
  UanCheck: "UanCheck",
  Awaiting: "Awaiting",
  Completed: "Completed",
  InSufficient: "InSufficient",
  InitiateEmail: "Initiate Email",
  Discrepancy: "Discrepancy",
  UnableToVerify: "UnableToVerify",
  action: "action",
  All: "All",
  Allcases: "All cases",
  Assign: "Assign",
  Assigned: "Assigned",
  Approve: "Approve",
  approve: "approve",
  Reject: "Reject",
  reject: "reject",
  Details: "Details",
  caseReceived: "caseReceived",
  caseStatus: "caseStatus",
  caseInitiation: "caseInitiation",
  WITHIN_TAT: "WITHIN TAT",
  LOST_TAT: "LOST TAT",
  APPROACHING_TAT: "APPROACHING TAT",
  Age: "Age",
  ColorCode: "Color Code",
  TimestampT: "T",
  One: "One",
  Two: "Two",
  Three: "Three",
  Remarks: "Remarks",
  case_id: "case_id",
  user_category: "user_category",
  Error: "Error",
  error: "error",
  success: "success",
  CriminalAddress: "Criminal Address",
  Permanent: "Permanent",
  permanent: "permanent",
  PermanentCriminalAddress: "Permanent Criminal Address",
  Current: "Current",
  current: "current",
  CurrentCriminalAddress: "Current Criminal Address",
  permCrimAddress: "permCrimAddress",
  intermediate: "intermediate",
  educationDetail: "educationDetail",
  reference: "reference",
  referenceDetail: "referenceDetail",
  referenceEvidence: "referenceEvidence",
  educationEvidence: "educationEvidence",
  NoOptionSelected: "No option selected",
  Noroutes: "No routes",
  QvVerify: "QvVerify",
  max200Char: "comment should contain maximum 200 characters!",
  forgotPassword: "Forgot Password?",
  alphabetsRegex: `^(?=.*[a-zA-Z])[a-zA-Z]+$`,
  // UserNameRegex: /^([a-zA-Z]+\s)*[a-zA-Z]+$/, //accepts numbers
  UserNameRegex: /^[a-zA-Z ]+$/, //accepts numbers
  AlphaNumaricRegex: "[A-Za-z0-9_]",
  PassportRegex: `^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$`,
  PanRegex: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
  DLregex: /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
  AadhaarCardRegex: /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/,
  VoterIdRegex: "^([a-zA-Z]){3}([0-9]){7}?$",
  TelephoneNumberRegex: "^s*(?:+?(d{1,3}))?[-. (]*(d{3})[-. )]*(d{3})[-. ]*(d{4})(?: *x(d+))?s*$",
  RcBookRegex: "^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$",
  // RcBookRegex: "[A-Z]{2}?[0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$",
  // _RcBookRegex: "^[A-Z]{2}[-][0-9]{1,2}[-][A-Z]{1,2}[-][0-9]{3,4}$",
  _RcBookRegex: "^[A-Z]{2}(-| |)[0-9]{1,2}(-| |)[A-Z]{1,2}(-| |)[0-9]{3,4}$",
  WebsiteRegex: "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
  GSTRegex: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
  // accepts: 18005551234, 1 800 555 1234, +1 800 555-1234, +86 800 555 1234, 1-800-555-1234, 1 (800) 555-1234, (800)555-1234, (800) 555-1234, (800)5551234, 800-555-1234, 800.555.1234, 800 555 1234x5678, 8005551234 x5678, 1    800    555-1234, 1----800----555-1234
  // ^\s*                #Line start, match any whitespaces at the beginning if any.
  // (?:\+?(\d{1,3}))?   #GROUP 1: The country code. Optional.
  // [-. (]*             #Allow certain non numeric characters that may appear between the Country Code and the Area Code.
  // (\d{3})             #GROUP 2: The Area Code. Required.
  // [-. )]*             #Allow certain non numeric characters that may appear between the Area Code and the Exchange number.
  // (\d{3})             #GROUP 3: The Exchange number. Required.
  // [-. ]*              #Allow certain non numeric characters that may appear between the Exchange number and the Subscriber number.
  // (\d{4})             #Group 4: The Subscriber Number. Required.
  // (?: *x(\d+))?       #Group 5: The Extension number. Optional.
  // \s*$                #Match any ending whitespaces if any and the end of string.

  UndefinedObject: undefined,
  Undefined: "undefined",
  myModal: "my-modal",
  ActiveProjects: "Active Projects",
  InvalidOtp: "Invalid Otp",
  HiIm: "Hi, I'm",
  AboutMe: "About me",
  IAmCEOFounderOf: "I am Founder of",
  TheProblemImTryingToSolve: "The Problem I'm trying to solve",
  MyBusinessIsLocatedIn: "My business is located in",
  YouCanFindUsAt: "You can find us at",
  MyCurrentProjects: "My current projects",
  ImLivingIn: `I'm living in`,
  MyProfessionalProfile: "My Professional Profile",
  MySkills: "My Skills",
  WhatWeDo: "What we do",
  TheServicesWeOffer: "The services we offer",
  WhereWeAreLocated: "Where we’re located",
  CurrentOffers: "Current Offers",
  Confirm: "Confirm",
  Project: "Project",
  Filter: "Filter",
  Projects: "Projects",
  Startups: "Startups",
  BETAs: "BETAs",
  Offers: "Offers",
  InvestYourPassionNow: "Invest your passion now!",
  JoinBetaToday: "Join BETA100 today!",
  JoinMeeting: "Join Meeting",
  ApplicationsSent: "Applications Sent",
  EmailSent: "Email Sent Successfully",
  ReviewApplication: "Review Application",
  RetractApplication: "Retract Application",
  SignIn: "Sign In",
  SignOut: "Sign Out",
  AlreadyHaveAnAccount: "Already have an account?",
  CreateAnAccount: "Create an account",
  Table: [
    { name: "Cash", value: "cash" },
    { name: "Equity", value: "equity" },
    { name: "Hybrid", value: "cash and equity" }
  ],
  EquityOfferedWillBe: [
    { name: "Shares", value: "shares", tooltip: "This is when someone owns a specified percentage of your company straight away." },
    {
      name: "Stock Options",
      value: "stock options",
      tooltip:
        "This is when you give someone the opportunity to own shares in your company in the future based on pre-established conditions."
    }
  ],
  ProjectName: "Project name",
  TheProblemWeAreSolving: "The problem we are solving",
  whereYouWillHaveImpact: "where you’ll have impact",
  thisIsForYouIf: "this is For You If",
  SkillsWeAreLookingFor: "Skills we’re looking for",
  levelOfExperienceNeeded: "level of experience needed",
  duration: "DURATION",
  commitment: "COMMITMENT",
  TotalProjectValue: "Total Project Value",
  BetaProjects: "BETA Projects",
  BetaHub: "BETA Hub",
  AboutTheProject: "About the project",
  AbourOffer: "About Offer",
  CouponCode: "Coupon Code",
  AboutStartUp: "About Start Up",
  clientSelectedTags: "clientSelectedTags",
  user_jwt: "user_jwt",
  ViewProject: "View Project",
  ViewOffer: "View Offer",
  ViewProfile: "View Profile",
  ViewStartUp: "View Start Up",
  ChangeStatus: "Change Status",
  Status: "Status",
  Save: "Save",
  Delete: "Delete",
  Download: "Download",
  YesDelete: "Yes, Delete",
  Publish: "Publish",
  Close: "Close",
  Cancel: "Cancel",
  GotIt: "Got it!",
  OK: "OK",
  Yes: "Y",
  No: "N",
  NA: "N.A.",
  _Yes: "Yes",
  _No: "No",
  View: "View",
  Verify: "Verify",
  VerifyasQC: " Verify as QC",
  loading: "loading...",
  ManageProject: "Manage Project",
  ArchiveProject: "Archive Project",
  CompleteProject: "Complete Project",
  TheProblemWeArereSolving: "The problem we're solving",
  WhereYouWillHaveImpact: "Where you’ll have impact",
  ThisIsForYouIf: "This is for you if",
  LevelOfExperienceNeeded: "Level of Experience Needed",
  Duration: "Duration",
  TheCommitmentRequired: "The commitment required",
  AboutTheStartup: "About the Startup",
  BIO: "BIO",
  ShareThisProject: "Share This Project",
  PasswordUpdatedSuccessfully: "password updated successfully!",

  PasswordRegExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  PhoneNumberRegExp: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  // the phone number regex accepts (123) 456-7890, (123)456-7890, 123-456-7890, 123.456.7890, 1234567890, +31636363634, 075-63546725 formats
  UrlRegex:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  CommaSeperatedValueRegex: `^(?:0|[1-9]\d*)(?:\.(?!.*000)\d+)?$`,
  Decline: "Decline",
  Accept: "Accept",
  LetsTalk: "Let's Talk",
  Send: "Send",
  Hashtag: "",
  CopyLink: "Copy link",
  Clear: "Clear",
  Copied: "Copied!",
  DateFormat_dd_MM_yyyy: "dd/MM/yyyy",
  DateFormat_DD_MM_YYYY: "DD/MM/YYYY",
  DateFormat_YYYY_MM_DD: "YYYY/MM/DD",
  DateFormat_YYYY__MM__DD: "YYYY-MM-DD",
  DateFormat_dd_MM_yyyy_hh_mm_a: "dd/MM/yyyy hh:mm a",
  DateFormat_DD_MM_YYYY_hh_mm_a: "DD/MM/YYYY hh:mm a",
  TZ_Formate: "YYYY-MM-DDTHH:mm:ss[Z]",
  TimeFormat_hh_mm_A: "HH:mm A",
  TimeFormat_hh_mm: "HH:mm",
  TimeFormat_LT: "LT",
  QualityOfTheVideoCall: "Quality of the Video Call",
  ChemistryBetweenYouTwo: "Chemistry between you two",
  Submit: "Submit",
  ProjectDecription: "Project Decription",
  KeyOutcomes: "Key Outcomes",
  ActivityLog: "Activity Log",
  BankHours: "Bank Hours",
  goodFitButtonDisabled: "good-fit-button-disabled",
  goodFitButtonGreen: "good-fit-button-green",
  goodFitButton: "good-fit-button",
  verifyButton: "verify-button",
  verifyButtonDisabled: "verify-button-disabled",
  verifyButtonBlue: "verify-button-blue",
  Disabled: "disabled",
  InterestedInApplying: "Interested in Applying?",
  JoinBETA100: "Join BETA100",
  AlreadyAMember: "Already a member?",
  ProjectCompletion: "Project Completion",
  ValueOfBetaBankHours: "Value of BETA Banked Hours",
  YouCantEditProjectAsBetaAlreadyInvolvedInProject: "You can't edit project as BETA already involved in project",

  IsentItHardToGetTheRightPeopleAroundTheTable: (
    <p className="slide-font-style-1" style={{ textAlign: "right" }}>
      ISN'T IT HARD TO GET <br />
      THE RIGHT PEOPLE <br />
      AROUND THE TABLE?
    </p>
  ),
  FindPeopleWhoBelieveInTheProblemYouAreSolving: (
    <p className="slide-font-style-2">
      Find people who believe in <br />
      the problem you are solving.
    </p>
  ),
  JoinAsFounder: `JOIN AS FOUNDER`,

  IsWorkNotThaatExcitingAnyMore: (
    <p className="slide-font-style-1">
      IS WORK NOT THAAT <br />
      EXCITING ANY MORE?
    </p>
  ),
  JoinAsInvestor: `JOIN AS BETA`,
  InvestTimeSkillsAndNetworkIntoProjects: (
    <p className="slide-font-style-2" style={{ textAlign: "left" }}>
      <span className="slide-font-style-3">Invest time, skills and network into projects</span> <br /> tackling problems close to your heart
      and <br /> build your own <span className="slide-font-style-3">portfolio of startups.</span>
    </p>
  ),

  AccessToTheStartupEcosystemIsMinutesAway: (
    <p className="slide-font-style-1" style={{ textAlign: "right" }}>
      ACCESS TO THE <br /> STARTUP ECOSYSTEM <br /> IS MINUTES AWAY
    </p>
  ),
  BecomeAPartner: <p className="slide-font-style-1">BECOME A PARTNER</p>,
  AndBoostYourSalesByProvidingYourServicesToStartups: (
    <p className="slide-font-style-2" style={{ textAlign: "left" }}>
      and boost your sales by <br /> providing your services <br /> to startups
    </p>
  ),

  JoinAsAnArray: `JOIN AS AN ARRAY`,
  ManageYourFoundersAndProjectsProtfolioWithBETA100: `Manage your founders and projects portfolio with BETA100`,

  // ReactScriptTag: `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3093060,hjsv:6}; a=o.getElementsByTagName("head")[0]; r=o.createElement("script");r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=")`,
  ReactScriptTag: `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3156893,hjsv:6};a=o.getElementsByTagName("head")[0];r=o.createElement("script");r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=");`,
  NotificationTimerMs: 60000, // 1 min

  LogOutMessage: `Do you really want to logout?`,
  AreYouSureYouWantToDeleteThisPersona: `Are you sure you want to delete this persona?`,
  FounderProfileDeleteMessage: `Do you really want to delete Founder profile?`,
  BetaProfileDeleteMessage: `Do you really want to delete BETA profile?`,
  PartnerProfileDeleteMessage: `Do you really want to delete Partner profile?`,
  ScheduleDeleteConfirmationHeading: `Are you sure you wish to delete this meeting? If so, the message below will be sent to the BETA.`,
  ShareYourMeetingExperience: "Share your meeting experience!",
  BETAProjects: "BETA Projects",
  FounderProjects: "Founder Projects",
  YourMeetingHasBeenScheduled: "Your meeting has been scheduled!",
  OfferDeleteConfirmationHeading: `Are you sure you wish to delete this offer ?`,
  SocialLoginHeading: `We need you to provide email id same as what you have used for social login. Also this has to be validated once with the otp. This email will be used for email correspondence.`,
  ChatWelcomeMessage: "Welcome to BETA 100",

  PleaseEnterTheCouponCode: "Please enter the coupon code!",
  ViewExistingFile: "View existing file",
  InvalidPanel: "Select a valid panel",

  bgvStatusValues: [
    { label: "Unable To Verify", value: "UnableToVerify" },
    { label: "Discrepancy", value: "Discrepancy" },
    { label: "Completed", value: "Completed" },
    { label: "Insufficient", value: "Insufficient" }
  ],

  foundNotFound: [
    { label: "Found", value: "found" },
    { label: "Not Found", value: "notFound" }
  ]
};

export const CompanyCheckForm = {
  companyNameRequired: "Company name required!",
  companyNameTooLong: "Company name is too long!",
  companyNameTooShort: "Company name is too short!",
  companyWebsiteRequired: "Company Website required!",
  companyWebsiteTooLong: "Company Website is too long!",
  companyWebsiteTooShort: "Company Website is too short!",
  validCompanyName: "Please enter the valid company name",
  validCompanyWebsite: "Please enter the valid company website",
};

export const componentsListArray = [
  "Address",
  "CompanyCheck",
  "Criminal",
  "CreditCheck",
  "CibilCheck",
  "CvValidation",
  "DatabaseCheck",
  "DirectorshipCheck",
  "DrugTest",
  "Education",
  "Employment",
  "GapVerification",
  "Identification",
  "PoliceVerification",
  "Reference",
  "SocialMedia",
  "UanCheck"
];

export const ConstantOwnershipTypeLabels = {
  Owned: "Owned",
  Rented: "Rented",
  Leased: "Leased",
  PG: "PG",
  Other: "Other"
};

export const ConstantOwnershipTypes = {
  Owned: "Owned",
  Rented: "Rented",
  Leased: "Leased",
  PG: "PG",
  Other: "Other"
};

export const ConstantRelationshipTypeLabels = {
  Self: "Self",
  Spouse: "Spouse",
  BloodRelative: "Blood relative",
  Neighbour: "Neighbour",
  Other: "Other"
};

export const ConstantRelationshipTypes = {
  Self: "Self",
  Spouse: "Spouse",
  BloodRelative: "BloodRelative",
  Neighbour: "Neighbour",
  Other: "Other"
};

export const creditCheckForm = {
  nameAlphabets: "Name should contain only alphabets!",
  nameRequired: "Name is required!",
  nameTooLong: "Name is too long!",
  nameTooShort: "Name is too short!",
};

export const criminal = {
  authorizedPersonNameLong: "Authorized Person Name is too long",
  authorizedPersonNameShort: "Authorized Person Name is too short",
  authorizedPersonNameRequired: "Authorized Person Name is required!",
};

export const DatabaseCheckForm = {
  dateRequired: "Dob is required!",
  nameRequired: "Name is required!",
  nameTooLong: "Name is too long!",
  nameTooShort: "Name is too short!",
  validName: "Please enter the valid name!",
  validDOB: "Must be the valid DOB!",
};

export const defaultRoundOffValue = 2

export const DigitalAddressTypeLabels = {
  Permanent: "Permanent",
  Current: "Current",
  IntermediateOne: "Intermediate 1",
  IntermediateTwo: "Intermediate 2"
};

export const DigitalAddressTypes = {
  Permanent: "Permanent",
  Current: "Current",
  IntermediateOne: "IntermediateOne",
  IntermediateTwo: "IntermediateTwo"
};

export const DigitalAddressVerificationForm = {
  AddressInvalid: "Address is invalid",
  AddressLong: "Address is too long",
  AddressShort: "Address is too short",
  AddressRequired: "Address is required",
  CommentInvalid: "Invalid comment",
  CommentLong: "Comment is too long",
  CommentShort: "Comment is too short",
  LandmarkImageRequired: "Landmark image is required. Click the button below to capture landmark image.",
  LatitudeRequired: "Latitude is required",
  LocationImageRequired: "Location image is required. Click the button below to capture location image.",
  LongitudeRequired: "Longitude is required",
  OtherOwnershipEmpty: "Please mention an ownership",
  OtherOwnershipInvalid: "Invalid ownership",
  OtherOwnershipLong: "Ownership is too long",
  OtherOwnershipShort: "Ownership is too short",
  OtherOwnershipRequired: "Ownership is required",
  OtherRelationshipEmpty: "Please mention a relationship",
  OtherRelationshipInvalid: "Invalid relationship",
  OtherRelationshipLong: "Relationship is too long",
  OtherRelationshipShort: "Relationship is too short",
  OtherRelationshipRequired: "Relationship is required",
  PeriodOfStayInvalid: "Period of stay is invalid",
  PeriodOfStayLong: "Period of stay is too long",
  PeriodOfStayRequired: "Period of stay is required",
  RespondantIdProofInvalid: "Respondant id proof is invalid. Image should be less than 5MB. Supported formats are .png, .jpg, .jpeg",
  RespondantIdProofRequired: "Respondant id proof is required",
  RespondantMobileInvalid: "Respondant mobile no. is invalid",
  RespondantMobileRequired: "Respondant mobile no. is required",
  RespondantNameInvalid: "Respondant name is invalid",
  RespondantNameLong: "Respondant name is too long",
  RespondantNameShort: "Respondant name is too short",
  RespondantNameRequired: "Respondant name is required",
  RespondantOwnershipInvalid: "Respondant ownership is invalid",
  RespondantOwnershipRequired: "Respondant ownership is required",
  RespondantRelationshipInvalid: "Respondant relationship is invalid",
  RespondantRelationshipRequired: "Respondant relationship is required",
  SelfieRequired: "Selfie is required. Click the button below to capture selfie.",
  SignatureInvalid: "Signature is invalid. Make sure the signature is clear.",
  SignatureRequired: "Signature is required",
  VerificationDateRequired: "Verification date is required",
};

export const DigitalAddressVerificationView = {
  AddressLocationInvalid: "Address entered by the candidate is invalid!",
  Location: "L",
  NoResponseFromCandidate: "No response from the candidate yet!",
  Timestamp: "TS"
};

export const DirectorShipCheckForm = {
  addressRequired: "Address required!",
  addressTooShort: "Address is Too Short!",
  companyNameRequired: "Company name required!",
  companyNameTooLong: "Company name is too long!",
  companyNameTooShort: "Company name is too short!",
  dateRequired: "Dob is required!",
  designationRequired: "Designation required!",
  designationTooLong: "Designation is too long!",
  designationTooShort: "Designation is too short!",
  nameRequired: "Name is required!",
  nameTooLong: "Name is too long!",
  nameTooShort: "Name is too short!",
  validCompanyName: "Please enter the valid company name",
  validDesignation: "Please enter the valid Designation!",
  validName: "Please enter the valid name!",
};

export const EducationForm = {
  cityRequired: "City is required!",
  cityTooLong: "City is too long!",
  cityTooShort: "City is too short!",
  collegeNameRequired: "College name is required!",
  collegeNameTooLong: "College name is too long!",
  collegeNameTooShort: "College name is too short!",
  percentageRequired: "Class / Division / Percentage is required!",
  percentageTooLong: "Class / Division / Percentage is too long!",
  percentageTooShort: "Class / Division / Percentage is too short",
  postalCodeRegex: `^[1-9][0-9]{5}$`,
  postalCodeRequired: "Postal code is required!",
  qualificationRequired: "Qualification is required!",
  qualificationTooLong: "Qualification is too long!",
  qualificationTooShort: "Qualification is too short!",
  requiredEmail: "Institution email is required!",
  rollNoRequired: "Roll number / Student Id is required!",
  studyPeriodRegex: "[A-Za-z0-9_]",
  studyPeriodRequired: "Study period is required!",
  studyPeriodTooLong: "Study period is too long!",
  telNoRequired: "Telephone number is required!",
  univNameRequired: "University name is required!",
  univNameTooLong: "University name is too long!",
  univNameTooShort: "University name is too short!",
  validCity: "Must contain only alphabets!",
  validCollegeName: "Must contain only alphabets!",
  validEmail: "Must be valid email!",
  validPercentage: "Must be valid Class / Division / Percentage!",
  validQualification: "Qualification is not valid!",
  validrollNo: "Must be valid Roll number / Student Id!",
  validStudyPeriod: "Must be the valid study period!",
  validTelNo: "Must be valid Telephone number!",
  validUniName: "Must contain only alphabets!",
  valiedPostalCode: "Must be valid postal code!"
};

export const EmailInitiation = {
  EmailsRequired: "Recipients are required!"
};

export const Employment = {
  Actual: "Actual",
  AddEmployment: "Add Employment",
  basicRequired: "Required!",
  City: "City",
  CityActual: "City Actual",
  CityIsTooLong: "City is too long!",
  CityIsTooShort: "City is too short!",
  CityStatus: "City Status",
  currCity: "currCity",
  currCityActual: "currCityActual",
  currCityStatus: "currCityStatus",
  currCityAlphabets: "City must contain only alphabets!",
  currCityRequired: "City is required!",
  currEmpCode: "currEmpCode",
  currEmpCodeActual: "currEmpCodeActual",
  currEmpCodeRequired: "Employee code is required!",
  currEmpCodeSpecial: "Special characters are not allowed!",
  currEmpCodeStatus: "currEmpCodeStatus",
  currEmpCtc: "currEmpCtc",
  currEmpCtcActual: "currEmpCtcActual",
  currEmpCtcRequired: "Employee CTC is required!",
  currEmpCtcStatus: "currEmpCtcStatus",
  currEmpCtcTooLong: "Salary must contain maximum 10 characters!",
  currEmpCtcTooShort: "Salary must contain atleast 3 characters!",
  currEmpLeavingReason: "currEmpLeavingReason",
  currEmpLeavingReasonActual: "currEmpLeavingReasonActual",
  currEmpLeavingReasonRequired: "Leaving reason is required!",
  currEmpLeavingReasonSpecial: "Special characters are not allowed!",
  currEmpLeavingReasonStatus: "currEmpLeavingReasonStatus",
  currEmpLeavingReasonTooLong: "Reason for leaving is too long!",
  currEmpName: "currEmpName",
  currEmpNameActual: "currEmpNameActual",
  currEmpNameAlphabets: "Employee name must contain only alphabets!",
  currEmpNameRequired: "Employee name is required!",
  currEmpNameStatus: "currEmpNameStatus",
  currEmpOfferLetter: "currEmpOfferLetter",
  currEmpPeriodRequired: "Period of employee is required!",
  currEmpReleivingLetter: "currEmpReleivingLetter",
  currEmpSalarySlip: "currEmpSalarySlip",
  currentlyEmployed: "Are you currently employed?",
  currEmployed: "currEmployed",
  currEmployedActual: "currEmployedActual",
  currEmployedStatus: "currEmployedStatus",
  CurrentlyEmployed: "Currently Employed",
  CurrentlyEmployedActual: "Currently Employed Actual",
  CurrentlyEmployedStatus: "Currently Employed Status",
  currHrAlphabets: "HR name must contain only alphabets!",
  currHrEmail: "currHrEmail",
  currHrEmailActual: "currHrEmailActual",
  currHrEmailRequired: "HR email is required!",
  currHrEmailStatus: "currHrEmailStatus",
  currHrName: "currHrName",
  currHrNameActual: "currHrNameActual",
  currHrNameRequired: "HR Name is required!",
  currHrNameStatus: "currHrNameStatus",
  currHrNameTooLong: "HR name is too long!",
  currHrNameTooShort: "HR name is too short!",
  currJobtitle: "currJobtitle",
  currJobtitleActual: "currJobtitleActual",
  currJobtitleAlphabets: "Job title must contain only alphabets!",
  currJobtitleRequired: "Job title is required!",
  currJobtitleStatus: "currJobtitleStatus",
  currJoinDate: "currJoinDate",
  currJoinDateActual: "currJoinDateActual",
  currJoinDateRequired: "Join date is required!",
  currJoinDateStatus: "currJoinDateStatus",
  currLocation: "currLocation",
  currLocationActual: "currLocationActual",
  currLocationRequired: "Location is required!",
  currLocationStatus: "currLocationStatus",
  currLocationSpecial: "Special characters are not allowed!",
  currManagerDetails: "currManagerDetails",
  currManagerDetailsActual: "currManagerDetailsActual",
  currManagerDetailsRequired: "Manager detail is required!",
  currManagerDetailsStatus: "currManagerDetailsStatus",
  currManagerEmail: "currManagerEmail",
  currManagerEmailActual: "currManagerEmailActual",
  currManagerEmailRequired: "Manager email is required!",
  currManagerEmailStatus: "currManagerEmailStatus",
  currManagerPhone: "currManagerPhone",
  currManagerPhoneActual: "currManagerPhoneActual",
  currManagerPhoneRequired: "Manager phone number is required!",
  currManagerPhoneStatus: "currManagerPhoneStatus",
  currOfferLetterRequired: "Offer letter is required!",
  currRelieving: "currRelieving",
  currRelievingLetterRequired: "Relieving letter is required!",
  currRelievingActual: "currRelievingActual",
  currRelievingRequired: "Relieving date is required!",
  currRelievingStatus: "currRelievingStatus",
  currSalarySlipRequired: "Salary Slip is required!",
  CurrentSalarySlip: "Current salary slip",
  currTelNo: "currTelNo",
  currTelNoActual: "currTelNoActual",
  currTelNoRequired: " Telephone number is required!",
  currTelNoStatus: "currTelNoStatus",
  EmailsRequired: "Email is required!",
  EmployeeCode: "Employee Code",
  EmployeeCodeActual: "Employee Code Actual",
  EmployeeCodeStatus: "Employee Code Status",
  employment: "employment",
  employments: "employments",
  employmentDetailAdditionalComment: "employmentDetailAdditionalComment",
  employmentDetailAuthenticatedBy: "employmentDetailAuthenticatedBy",
  employmentDetailEligibleForRehire: "employmentDetailEligibleForRehire",
  employmentDetailContactDetails: "employmentDetailContactDetails",
  employmentDetailModeOfVerification: "employmentDetailModeOfVerification",
  employmentDetailVerificationStatus: "employmentDetailVerificationStatus",
  employmentDetail: "employmentDetail",
  employmentEvidence: "employmentEvidence",
  EmploymentEvidence: "Employment Evidence",
  employmentRemarks: "employmentRemarks",
  employmentStatus: "employmentStatus",
  EmploymentVerification: "Employment Verification",
  EmployerName: "Employer Name",
  EmployerNameStatus: "Employer Name Status",
  EmployerNameActual: "Employer Name Actual",
  fresher: "fresher",
  Fresher: "Fresher",
  fresherActual: "fresherActual",
  fresherStatus: "fresherStatus",
  FresherActual: "Fresher Actual",
  FresherStatus: "Fresher Status",
  hrNameIsRequired: "HR name is required!",
  hrNameTooLong: "HR Name is too long!",
  hrNameTooShort: "HR Name is too short!",
  HREmail: "HR Email",
  HREmailActual: "HR Email Actual",
  HREmailStatus: "HR Email Status",
  HRName: "HR Name",
  HRNameActual: "HR Name Actual",
  HRNameStatus: "HR Name Status",
  invalidOption: "Invalid option!",
  JobTitleIsTooLong: "Job title is too long!",
  JobTitleIsTooShort: "Job title is too short!",
  JobTitle: "Job Title",
  JobTitleActual: "Job Title Actual",
  JobTitleStatus: "Job Title Status",
  JoiningDate: "Joining Date",
  JoiningDateActual: "Joining Date Actual",
  JoiningDateStatus: "Joining Date Status",
  LastDrawnSalary: "Last Drawn Salary",
  LastDrawnSalaryActual: "Last Drawn Salary Actual",
  LastDrawnSalaryStatus: "Last Drawn Salary Status",
  LeavingReason: "Leaving Reason",
  LeavingReasonActual: "Leaving Reason Actual",
  LeavingReasonStatus: "Leaving Reason Status",
  Location: "Location",
  LocationActual: "Location Actual",
  LocationStatus: "Location Status",
  LocationTooLong: "Location is too long!",
  LocationTooShort: "Location is too short!",
  managerDetailsTooLong: "Manager details is too long!",
  managerDetailsTooShort: "Manager details is too short!",
  ManagerDetails: "Manager Details",
  ManagerDetailsActual: "Manager Details Actual",
  ManagerDetailsStatus: "Manager Details Status",
  ManagerEmail: "Manager Email",
  ManagerEmailActual: "Manager Email Actual",
  ManagerEmailStatus: "Manager Email Status",
  ManagerNameAndDesignation: "Manager name and designation",
  ManagerPhone: "Manager Phone",
  ManagerPhoneActual: "Manager Phone Actual",
  ManagerPhoneStatus: "Manager Phone Status",
  NameIsTooLong: "Name is too long!",
  NameIsTooShort: "Name is too short!",
  NameOfTheEmployer: "Name of the employer",
  NoFileUploadedYet: "No file uploaded yet",
  noSepcial: "Special characters are not allowed!",
  numbersNotAllowed: "Numbers are not allowed!",
  OfferLetter: "Offer letter",
  onlyAlphabets: "Must contain only alphabets!",
  Qc: "Qc",
  ReasonForLeaving: "Reason for leaving",
  reasonTooLong: "reason is too long!",
  reasonTooShort: "reason is too short!",
  ReleivingDate: "Releiving Date",
  ReleivingDateActual: "Releiving Date Actual",
  ReleivingDateStatus: "Releiving Date Status",
  ReleivingLetter: "Releiving letter",
  Remove: "Remove",
  TelephoneNo: "Telephone No",
  TelephoneNoActual: "Telephone No Actual",
  TelephoneNoStatus: "Telephone No Status",
  validEmail: "Must be valid email!",
  validTelNo: "Must be valid Telephone number!",
  Verification: "Verification",
  VerifiedToBeCorrect: "Verified to be correct",
  VerifiedToBeInCorrect: "Verified to be incorrect",
  YesNo: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
  ],
};

export const educationKeys = {
  education: "education",
  Education: "Education",
  educations: "educations",

  Qualification: "Qualification",
  RollNo: "Register No./Roll No. ",
  PeriodOfStudy: "Period of Study ",
  College: "College/Institute",
  University: "University/ Board",
  Percentage: "Class / Division / %",
  AdditionalComment: "Additional Comments",
  AuthenticatedBy: "Authenticated by",
  ModeOfVerification: "Mode of Verification",
  VerificationStatus: "Status",

  QualificationActual: "Qualification Actual",
  RollNoActual: "Roll No.  Actual",
  CollegeActual: "College Actual",
  PeriodOfStudyActual: "Period of Study Actual",
  PercentageActual: "Class / % Actual",
  UniversityActual: "University Actual",
  ClassActual: "Class Actual",

  // Db keys
  educationDetail: "educationDetail",
  educationEvidence: "educationEvidence",
  educationRemarks: "educationRemarks",
  educationStatus: "educationStatus",
  Qc: "Qc",

  collegeName: "collegeName",
  univName: "univName",
  qualification: "qualification",
  studyPeriod: "studyPeriod",
  rollNo: "rollNo",
  percentage: "percentage",

  collegeNameActual: "collegeNameActual",
  univNameActual: "univNameActual",
  qualificationActual: "qualificationActual",
  studyPeriodActual: "studyPeriodActual",
  rollNoActual: "rollNoActual",
  percentageActual: "percentageActual",

  additionalComment: "additionalComment",
  authenticatedBy: "authenticatedBy",
  modeOfVerification: "modeOfVerification",
  verificationStatus: "verificationStatus",
}

export const addressKeys = {
  address: "Address",
  landMark: "LandMark",
  city: "City",
  state: "State",
  country: "Country",
  stayPeriod: "Stay Period",
  postalCode: "Postal Code",

  addressActual: "Address Actual",
  landMarkActual: "Landmark Actual",
  cityActual: "City Actual",
  stateActual: "State Actual",
  countryActual: "Country Actual",
  stayPeriodActual: "Stay Period Actual",
  postalCodeActual: "Postal Code Actual",

  respondentName: "Respondent Name",
  remarks: "Remarks",
  VerificationStatus: "Status",

}

export const FACING_MODES = {
  ENVIRONMENT: "environment",
  USER: "user"
}

export const factoDetails = {
  Name: "FACTO TECHNOLOGY PVT LTD",
  Address: "No 44, 1st Floor, 1st cross Road, 1st Block, HBR Layout, Bengaluru -560043",
  GSTIN: "29AAECF7947G1Z7",
  CIN: "U74140KA2021PTC153792",
  PAN: "AAECF7947G",
  SAC: "998519",
  FactoState: "Karnataka",
  InvoiceTermDays: 30
}

export const formikValueConstant = {
  commentCharacterLimit: 2000,
  remarkCharacterLimit: 2000,
}

export const GapVerificationForm = {
  GapPeriodAlphabets: "Period of gap must contain only alphabets!",
  GapPeriodRequired: "Period of gap is required!",
  GapPeriodTooLong: "Period of gap is too long!",
  GapPeriodTooShort: "Period of gap is too short!",
  GapReasonAlphabets: "Reason for gap must contain only alphabets!",
  GapReasonRequired: "Reason for gap is required!",
  GapReasonTooLong: "Reason for gap is too long!",
  GapReasonTooShort: "Reason for gap is too short!",
  GapType: [
    { label: "Employment", value: "Employment" },
    { label: "Education", value: "Education" }
  ],
  GapTypeAlphabets: "Type os gap must contain only alphabets!",
  GapTypeRequired: "Type os gap is required!",
  GapTypeTooLong: "Type os gap is too long!",
  GapTypeTooShort: "Type os gap is too short!",
};

export const GoogleMapsConstants = {
  addressLocationColor: "#63A088",
  addressLocationMarkerColor: "green",
  circleRadius: 200,
  deviceLocationColor: "#ff570a",
  deviceLocationMarkerColor: "red",
};

export const GstStateCodes = {
  "Andaman and Nicobar Islands": 35,
  "Andhra Pradesh": 37,
  "Arunachal Pradesh": 12,
  "Assam": 18,
  "Bihar": 10,
  "Chandigarh": 4,
  "Chhattisgarh": 22,
  "Dadra and Nagar Haveli": 26,
  "Daman and Diu": 25,
  "Delhi": 7,
  "Goa": 30,
  "Gujarat": 24,
  "Haryana": 6,
  "Himachal Pradesh": 2,
  "Jammu and Kashmir": 1,
  "Jharkhand": 20,
  "Karnataka": 29,
  "Kerala": 32,
  "Ladakh": 38,
  "Lakshadweep": 31,
  "Madhya Pradesh": 23,
  "Maharashtra": 27,
  "Manipur": 14,
  "Meghalaya": 17,
  "Mizoram": 15,
  "Nagaland": 13,
  "Odisha": 21,
  "Other Territory": 97,
  "Puducherry": 34,
  "Punjab": 3,
  "Rajasthan": 8,
  "Sikkim": 11,
  "Tamil Nadu": 33,
  "Telangana": 36,
  "Tripura": 16,
  "Uttar Pradesh": 9,
  "Uttarakhand": 5,
  "West Bengal": 19,
  "Foreign Country": 96,
}

// GST constants in percentage
export const GSTValues = {
  CGST_Rate: 9,
  SGST_Rate: 9,
  IGST_Rate: 18,
}

export const IdentificationArray = [
  "aadharEvidence",
  "drivingLicenceEvidence",
  "panEvidence",
  "passportEvidence",
  "rcBookEvidence",
  "voterIdEvidence",
]

export const IdentificationForm = {
  aadharEvidence: "aadharEvidence",
  AadharDobRequired: "Aadhar dob is required!",
  AadharFileRequired: "Aadhar file is required!",
  AadharNameRequired: "Aadhar name is required!",
  AadharNameTooLong: "Aadhar name is too long!",
  AadharNameTooShort: "Aadhar name is too short!",
  AadharNoIsRequired: "Aadhar number is required!",
  DlDobRequired: "Driving license dob is required!",
  DlNameRequired: "Driving license name is required!",
  DlNameTooLong: "Driving license name is too long!",
  DlNameTooShort: "Driving license name is too short!",
  DrivingLicenseNoIsRequired: "Driving License number is required!",
  panEvidence: "panEvidence",
  PanDobRequired: "Pan dob is required!",
  PanNameRequired: "Pan name is required!",
  PanNameTooLong: "Pan name is too long!",
  PanNameTooShort: "Pan name is too short!",
  PanNumberIsRequired: "Pan number is required!",
  PassportDobRequired: "Passport dob is required!",
  PassportNameRequired: "Passport name is required!",
  PassportNameTooLong: "Passport name is too long!",
  PassportNameTooShort: "Passport name is too short!",
  PassportNoIsRequired: "Passport number is required!",
  RCBookDobRequired: "RC Book dob is required!",
  RCBookNameRequired: "RC Book name is required!",
  RCBookNameTooLong: "RC Book name is too long!",
  RCBookNameTooShort: "RC Book name is too short!",
  RCBookNoIsRequired: "RC Book number is required!",
  ValidAadhar: "Must be valid aadhar number!",
  ValidDL: "Driving license must be in KA-0619850034761 format!",
  ValidPan: "Pan number must be in BNZAA2318J format!",
  ValidPassport: "Passport number must be in A2096457 format!",
  ValidRcBook: "RC book number must be in KA 14 AA 8909 format!",
  ValidVoterId: "Voter id number must be in ABE1234566 format!",
  VoterIdDobRequired: "Voter Id dob is required!",
  VoterIdNameRequired: "Voter Id name is required!",
  VoterIdNameTooLong: "Voter Id name is too long!",
  VoterIdNameTooShort: "Voter Id name is too short!",
  VoterIdNoIsRequired: "Voter Id number is required!",

  iDNameTooLong: "Name is too long!",
  iDNameTooShort: "Name is too short!",
};

export const InvoiceFilterForm = {
  ClientRequired: "Client is required!",
  InvoiceTypeRequired: "Invoice type is required!",
  MonthRequired: "Month is required!",
  NoFiltersApplied: "No filters selected. Apply filters to fetch invoice data!",
  YearRequired: "Year is required!",
}

export const invoiceTypes = {
  Commercial: "Commercial",
  Proforma: "Proforma",
}

export const negligibleSubComponents = [
  // componentNamesActual.DigitalAddress
];

export const oneToTenArray = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"];

export const physicalAddressEvidences = {
  Permanent: "evidenceFile2",
  Current: "evidenceFile1",
  IntermediateOne: "evidenceFile3",
  IntermediateTwo: "evidenceFile4"
}

export const PhysicalAddressTypes = {
  Permanent: "Permanent",
  Current: "Current",
  IntermediateOne: "IntermediateOne",
  IntermediateTwo: "IntermediateTwo"
};

export const PhysicalBgvAddressTypes = {
  permanentAddress: "Permanent Address",
  currentAddress: "Current Address",
  intermediateAddressOne: "Intermediate Address One",
  intermediateAddressTwo: "Intermediate Address Two",
  IntermediateAddress1: "Intermediate Address 1",
  IntermediateAddress2: "Intermediate Address 2",
};

export const PoliceVerificationForm = {
  AadharNoIsRequired: "Aadhar number is required!",
  AddressTypeInvalid: "Address type is invalid!",
  AddressTypeShouldBe: "Address type should be Permanent or Current!",
  AddressTypeRequired: "Address type is required!",
  AddressRequired: "Address is required!",
  AddressTooLong: "Address is too long!",
  AddressTooShort: "Address is too short!",
  PeriodOfStayRequired: "Period of stay is required!",
  PeriodOfStayTooLong: "Period of stay is too long!",
  PeriodOfStayTooShort: "Period of stay is too short!",
  ValidAadhar: "Must be valid aadhar number!",
};

export const PortalUserRoles = {
  Accounts: "Accounts",
  Admin: "Admin",
  Client: "Client",
  ClientUserTypes: {
    Employee: "Employee",
    HR: "HR",
  },
  CstSupervisor: "CST Supervisor",
  CstUser: "CST",
  DigitalAddressVerification: "DigitalAddressVerification",
  OpsSupervisor: "Operation Supervisor",
  OpsTeam: "Operation Team",
  QualityControl: "QC",
  Sales: "Sales",
  Vendor: "Vendor",
};

export const PortalUserRolesDisplayText = {
  Accounts: "Accounts",
  Admin: "Admin",
  Client: "Client",
  ClientUserTypes: {
    HR: "HR",
    Employee: "Employee"
  },
  CstSupervisor: "CST Supervisor",
  CstUser: "CST",
  DigitalAddressVerification: "DigitalAddressVerification",
  OpsSupervisor: "Operation Supervisor",
  OpsTeam: "Operation Team",
  QualityControl: "Quality Control",
  Sales: "Sales",
  Vendor: "Vendor",
};

export const QcNaming = {
  AdditionalComment: "Additional Comment",
  AuthenticatedBy: "Authenticated By",
  BseVerificationStatus: "BSE Verification Status",
  ContactDetails: "Contact Details",
  DateVerified: "Date Verified",
  EducationQualificationMismatch: "Education Qualification Mismatch",
  EligibleForRehire: "Eligible For Rehire",
  GlobalVerificationStatus: "Global Verification Status",
  GoogleVerificationStatus: "Google Verification Status",
  IndiaSpecificVerificationStatus: "India Specific Verification Status",
  McaVerificationStatus: "MCA Verification Status",
  ModeOfVerification: "Mode Of Verification",
  NasscomVerificationStatus: "NASSCOM Verification Status",
  NseVerificationStatus: "NSE Verification Status",
  TypeOfVerification: "Type Of Verification",
  VerificationStatus: "Verification Status",
  WebAndMediaVerificationStatus: "Web and Media Verification Status",
  WorkExperienceMismatch: "Work Experience Mismatch",
};

export const Reference = {
  ContactDetailsInvalid: "Contact details is invalid!",
  ContactDetailsRequired: "Contact details is required!",
  ContactDetailsTooLong: "Contact details is too long!",
  ContactDetailsTooShort: "Contact details is too short!",
  ContactRelationshipInvalid: "Invalid contact relationship!",
  ContactRelationshipRequired: "Contact relationship is required!",
  ContactRelationshipTooLong: "Contact relationship is too long!",
  ContactRelationshipTooShort: "Contact relationship is too short!",
  DesignationAlphabets: "Designation must contain only alphabets!",
  DesignationRequired: "Designation is required!",
  DesignationTooLong: "Designation is too long!",
  DesignationTooShort: "Designation is too short!",
  OrganizationNamemustAlphabets: "Organization Name must contain only alphabets!",
  OrganizationNameRequired: "Organization Name is required!",
  OrganizationNameTooLong: "Organization Name is too Long!",
  OrganizationNameTooShort: "Organization Name is too short!",
  RespondantEmailRequired: "Respondant email is required!",
  RespondantEmailValid: "Must be a valid email",
  RespondentNameAlphabets: "Respondent Name must contain only alphabets!",
  RespondentNameRequired: "Respondent Name is required!",
  RespondentNameTooLong: "Respondent Name is too long!",
  RespondentNameTooShort: "Respondent Name is too short!",
};

export const referenceKeys = {
  contactDetails: "contactDetails",
  contactDetailsActual: "contactDetailsActual",
  contactRelationship: "contactRelationship",
  contactRelationshipActual: "contactRelationshipActual",
  designation: "designation",
  designationActual: "designationActual",
  organizationName: "organizationName",
  organizationNameActual: "organizationNameActual",
  Reference: "Reference",
  respondentName: "respondentName",
  respondentNameActual: "respondentNameActual",
  respondentEmail: "respondentEmail",
  respondentEmailActual: "respondentEmailActual",

  ContactDetails: "Contact Details",
  ContactDetailsActual: "Contact Details Actual",
  ContactRelationship: "Contact Relationship",
  ContactRelationshipActual: "Contact Relationship Actual",
  Designation: "Designation",
  DesignationActual: "Designation Actual",
  OrganizationName: "Organization Name",
  OrganizationNameActual: "Organization Name Actual",
  RespondentName: "Respondent Name",
  RespondentNameActual: "Respondent Name Actual",
  RespondentEmail: "Respondent Email",
  RespondentEmailActual: "Respondent Email Actual",
}

export const ReferenceVerification = {
  additionalCommentsRequired: "Additional comments is required!",
  areasToImproveRequired: "Areas to improve is required!",
  associationPeriodRequired: "Association Period is required!",
  jobRecommendationRequired: "Job recommendation is required!",
  professionalCompetenceRequired: "Professional Competence is required!",
  strengthRequired: "Strength is required!",
};

export const SocialMedia = {
  faceBookRequired: "Enter the facebook account name or link!",
  instagramRequired: "Enter the instagram account name or link!",
  linkedInRequired: "Enter the linkedIn account name or link!",
  twitterRequired: "Enter the twitter account name or link!",
};

export const UanCheck = {
  InvalidUanNumber: "Invalid UAN number",
  UanNameRequired: "UAN name is required!",
  UanNameTooLong: "UAN name is too long!",
  UanNameTooShort: "UAN name is too short!",
  UanNumberTooLong: "UAN Number is too long!",
  UanNumberTooShort: "UAN Number is too short!",
  UanNumberRequired: "UAN number is required",
};

export const useParamConstants = {
  AllCases: "all_cases",
  AllClients: "all_clients",
  clientname: "clientname"
}

export const VerificationCommon = {
  AdditionalCommentRequired: "Additional comment is required!",
  AdditionalCommentTooLong: "Additional comment is too long!",
  AdditionalCommentTooShort: "Additional comment is too short!",
  AddressProofRequired: "Address proof is required",
  AuthenticatedByInvalid: "Authenticated by is Invalid!",
  AuthenticatedByRequired: "Authenticated by is required!",
  AuthenticatedByTooLong: "Authenticated by is too long!",
  AuthenticatedByTooShort: "Authenticated by is too short!",
  ChangeStatusRequired: "Change status is required!",
  CommonInvalidOptionInvalid: "Invalid option!",
  CommonInvalidOptionRequired: "Required!",
  CommonTooLong: "Too long!",
  CommonTooShort: "Too short!",
  CompletedDateRequired: "Completed date is required",
  ContactDetailsInvalid: "Contact detail is too long!",
  ContactDetailsRequired: "Contact detail is required!",
  ContactDetailsTooLong: "Contact detail is too long!",
  ContactDetailsTooShort: "Contact detail is too short!",
  EligibleForRehireInvalid: "Eligibility is invalid!",
  EligibleForRehireRequired: "Eligibility is required!",
  EvidenceTypeInvalid: "Evidence type is invalid!",
  EvidenceTypeRequired: "Evidence type is required!",
  InvalidDefault: "Invalid!",
  ModeOfVerificationInvalid: "Mode of verification is invalid!",
  ModeOfVerificationRequired: "Mode of verification is required!",
  PleaseAssignToACSTMember: "Please assign to a CST member!",
  RemarkRequired: "Remark is required!",
  RemarkTooLong: "Remark is too long!",
  RemarkTooShort: "Remark is too short!",
  RequiredDefault: "Required!",
  RespondentNameInvalid: "Respondent name is invalid!",
  RespondentNameRequired: "Respondent name is required!",
  RespondentNameTooLong: "Respondent name is too long!",
  RespondentNameTooShort: "Respondent name is too short!",
  StatusDescriptionInvalid: "Status description is invalid!",
  StatusDescriptionRequired: "Status description is required!",
  StatusDescriptionTooLong: "Status description is too long!",
  StatusDescriptionTooShort: "Status description is too short!",
  StatusRequired: "Status is required!",
  StatusInvalid: "Status is invalid!",
  VerificationStatusInvalid: "Verification status is invalid!",
  VerificationStatusRequired: "Verification status is required!",
  VerificationTypeInvalid: "Verification type is invalid!",
  VerificationTypeRequired: "Verification type is required!",
  VerificationModeInvalid: "Verification mode is invalid!",
  VerificationModeRequired: "Verification mode is required!",
  VerifiedDateRequired: "Verification date is required",
};

export const componentUtilityKeys = {
  addressCurrent: "address-current",
  addressPermanent: "address-permanent",
  addressIntermediateOne: "address-intermediateone",
  addressIntermediateTwo: "address-intermediatetwo",
  educationOne: "education-one",
  educationTwo: "education-two",
  educationThree: "education-three",
  employmentOne: "employment-one",
  employmentTwo: "employment-two",
  employmentThree: "employment-three",
  employmentFour: "employment-four",
  employmentFive: "employment-five",
  employmentSix: "employment-six",
  employmentSeven: "employment-seven",
  employmentEight: "employment-eight",
  employmentNine: "employment-nine",
  employmentTen: "employment-ten",
  referenceOne: "reference-one",
  referenceTwo: "reference-two",
  referenceThree: "reference-three",
  criminalCurrent: "criminal-current",
  criminalPermanent: "criminal-permanent",
  policeOne: "police-police",
  policeTwo: "police-challan",
  cvOne: "cv-one",
  cvTwo: "cv-two",
  idAadhar: "id-aadhar",
  idPan: "id-pan",
  idDriving: "id-driving",
  idPassport: "id-passport",
  idRc: "id-rc",
  idVoter: "id-voter",
  socialmediaFacebook: "socialmedia-facebook",
  socialmediaInstagram: "socialmedia-instagram",
  socialmediaLinkedIn: "socialmedia-linkedIn",
  socialmediaTwitter: "socialmedia-twitter",
  globalDB: "globaldb",
  creditCheck: "creditcheck",
  cibilCheck: "cibilcheck",
  drugTest: "drugtest",
  uan: "uan",
  gap: "gap",
  company: "company",
  directorship: "directorship"
}

export const componentDataKeyMapping = {
  address: {
    current: "currentAddress",
    permanent: "permanentAddress",
    intermediateone: "intermediateAddressOne",
    intermediatetwo: "intermediateAddressTwo",

    bgvKey: "case_address_bgv",
  },

  education: {
    one: "educationDetailOne",
    two: "educationDetailTwo",
    three: "educationDetailThree",

    bgvKey: "case_education_bgv",
  },

  employment: {
    one: "employmentDetailOne",
    two: "employmentDetailTwo",
    three: "employmentDetailThree",
    four: "employmentDetailFour",
    five: "employmentDetailFive",
    six: "employmentDetailSix",
    seven: "employmentDetailSeven",
    eight: "employmentDetailEight",
    nine: "employmentDetailNine",
    ten: "employmentDetailTen",

    bgvKey: "case_employment_bgv",

  },

  reference: {
    one: "referenceDetailOne",
    two: "referenceDetailTwo",
    three: "referenceDetailThree",

    bgvKey: "case_reference_bgv",
    urlField: "referenceEvidenceFile"
  },
};

export const uploadEvidenceKeyMapping = {
  address: {
    current: "currentAddressEvidence",
    permanent: "permanentAddressEvidence",
    intermediateone: "intermediateAddressOneEvidence",
    intermediatetwo: "intermediateAddressTwoEvidence",

    bgvKey: "case_address_bgv",
    urlField: "evidenceFile"
  },

  education: {
    one: "educationEvidenceOne",
    two: "educationEvidenceTwo",
    three: "educationEvidenceThree",

    bgvKey: "case_education_bgv",
    urlField: "educationEvidence"
  },

  employment: {
    one: "employmentEvidenceOne",
    two: "employmentEvidenceTwo",
    three: "employmentEvidenceThree",
    four: "employmentEvidenceFour",
    five: "employmentEvidenceFive",
    six: "employmentEvidenceSix",
    seven: "employmentEvidenceSeven",
    eight: "employmentEvidenceEight",
    nine: "employmentEvidenceNine",
    ten: "employmentEvidenceTen",

    bgvKey: "case_employment_bgv",
    urlField: "employmentEvidence"
  },

  reference: {
    one: "referenceEvidenceOne",
    two: "referenceEvidenceTwo",
    three: "referenceEvidenceThree",

    bgvKey: "case_reference_bgv",
    urlField: "referenceEvidenceFile"
  },

  criminal: {
    current: "currentAddressEvidence",
    permanent: "permanentAddressEvidence",

    bgvKey: "case_criminal_bgv",
    evidenceField: "addressEvidence",
  },

  police: {
    police: "policeVerificationEvidence",
    challan: "challanEvidence",

    bgvKey: "case_police_bgv",
    evidenceField: "policeVerificationEvidence",
  },

  cv: {
    one: "cvEvidenceFile1",
    two: "cvEvidenceFile2",

    bgvKey: "case_cv_bgv",
    evidenceField: "cvEvidence",
  },

  id: {
    aadhar: "aadharEvidence",
    pan: "panEvidence",
    driving: "drivingLicenceEvidence",
    passport: "passportEvidence",
    rc: "rcBookEvidence",
    voter: "voterIdEvidence",

    bgvKey: "case_id_bgv",
    evidenceField: "identificationEvidence",
  },

  socialmedia: {
    facebook: "facebookEvidence",
    instagram: "instagramEvidence",
    linkedIn: "linkedInEvidence",
    twitter: "twitterEvidence",

    bgvKey: "case_socialmedia_bgv",
    evidenceField: "socialMediaEvidence",
  },

  globaldb: {
    bgvKey: "case_globalDB_bgv",
    evidenceField: "dbVerificationEvidence",
    urlField: "dbVerificationEvidenceFile",
  },

  creditcheck: {
    bgvKey: "case_creditCheck_bgv",
    evidenceField: "creditCheckEvidence",
    urlField: "creditCheckEvidenceFile",
  },

  cibilcheck: {
    bgvKey: "case_cibilCheck_bgv",
    evidenceField: "cibilCheckEvidence",
    urlField: "cibilCheckEvidenceFile",
  },

  drugtest: {
    bgvKey: "case_drugTest_bgv",
    evidenceField: "lipidProfileEvidence",
    urlField: "lipidProfileEvidenceFile",
  },

  uan: {
    bgvKey: "case_uan_bgv",
    evidenceField: "uanCheckEvidence",
    urlField: "uanCheckEvidenceFile",
  },

  gap: {
    bgvKey: "case_gap_bgv",
    evidenceField: "gapVerificationEvidence",
    urlField: "gapVerificationEvidenceFile",
  },

  company: {
    bgvKey: "case_company_bgv",
    evidenceField: "companyCheckEvidence",
    urlField: "companyCheckEvidenceFile",
  },

  directorship: {
    bgvKey: "case_directorship_bgv",
    evidenceField: "directorshipCheckEvidence",
    urlField: "directorshipCheckEvidenceFile",
  },
};